// Constants
import { imageSVG, OBSTACLE_TYPES, TARGET_TYPES } from "../constants/constants";

// Utilities
import { createSVGImage } from "./createSvgImage";

export const imageType = (point) => {
  switch (point.type) {
    case TARGET_TYPES.COIN.value:
      return createSVGImage(imageSVG.coin, point);
    case TARGET_TYPES.STAR.value:
      return createSVGImage(imageSVG.star, point);
    case TARGET_TYPES.FLAG.value:
      return createSVGImage(imageSVG.flag, point);
    case OBSTACLE_TYPES.ELLIPSE.value:
      return createSVGImage(imageSVG.circle, point);
    case OBSTACLE_TYPES.TRIANGLE.value:
      return createSVGImage(imageSVG.triangle, point);
    case OBSTACLE_TYPES.RECTANGLE.value:
      return createSVGImage(imageSVG.square, point);
    default:
      return null;
  }
};
