// Store
import { useTokenStore } from "../store/store";

export const useTokenData = () => {
  const { token } = useTokenStore();

  let base64Url;
  let base64;
  let jsonPayload;
  let deCodedJWT;
  if (token) {
    base64Url = token.split(".")[1];
    base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    deCodedJWT = JSON.parse(jsonPayload);
  }
  return deCodedJWT
    ? {
        userId: deCodedJWT.user_id,
        email: deCodedJWT.email,
      }
    : {
        userId: null,
        email: null,
      };
};
