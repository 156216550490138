import React from "react";

import { InputSwitch } from "primereact/inputswitch";

const GridSettings = ({ snapToGrid, setSnapToGrid }) => {
  return (
    <div className="flex items-center gap-3">
      <p>Snap To Grid:&nbsp;</p>
      <div className="flex items-center gap-3">
        <InputSwitch
          checked={snapToGrid}
          onChange={(e) => {
            setSnapToGrid(e.value);
          }}
        />
      </div>
    </div>
  );
};

export default GridSettings;
