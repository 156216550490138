import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

// Prime React Components
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";

export const Dashboard = () => {
  const navigate = useNavigate();
  const toast = useRef(null);

  return (
    <div
      style={{ minHeight: "calc(100vh - 9rem)" }}
      className="w-full flex gap-8 items-center justify-center"
    >
      <Toast ref={toast} />
      {/* Marble Game Button */}
      <Card
        onClick={() => {
          navigate("/scenes");
        }}
        className="bg-[#243142] text-white cursor-pointer hover:scale-[0.99] transition-all font-mono tracking-widest font-semibold text-2xl w-[80vw]"
      >
        <p className="text-center uppercase">Marble Track</p>
      </Card>
      {/* Cannon Game Button */}
      <Card
        onClick={() => {
          toast.current.show({
            severity: "warn",
            summary: "Coming Soon",
            detail: "Under development",
            life: 3000,
          });
        }}
        className="bg-[#243142] text-white cursor-pointer hover:scale-[0.99] transition-all font-mono tracking-widest font-semibold text-2xl w-[80vw]"
      >
        <p className="text-center uppercase">Cannon</p>
      </Card>
    </div>
  );
};
