import React, { useRef } from "react";

// Store
import { useObjectStore } from "../store/store";

// Components
import { ObstacleSettings } from "./ObstacleSettings";

// Constants
import {
  ANIMATION_TYPES,
  DEFAULT_OBSTACLE_OBJECT_DATA,
  OBJECT_TYPES,
  OBSTACLE_TYPES,
  TARGET_TYPES,
} from "../constants/constants";

// Prime react components
import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";
import { ColorPicker } from "primereact/colorpicker";
import { TargetSettings } from "./TargetSettings";
import ColorPaletteOverlay from "./ColorPaletteOverlay";
import { Button } from "primereact/button";

export const ObjectSettings = ({ element, setElement, scenesOptions }) => {
  const overlayRef = useRef();
  const { focusedObject, setFocusedObject } = useObjectStore();

  const objects =
    focusedObject.type === OBJECT_TYPES.TARGET
      ? element.targets
      : element.obstacles;

  const object = objects[focusedObject.index];

  const handleClone = () => {
    const offset = 5;
    const clonedObject = structuredClone(object);

    clonedObject.objectPosition = {
      x: clonedObject.objectPosition.x + offset,
      y: clonedObject.objectPosition.y + offset,
    };
    if (clonedObject.curvePoints) {
      clonedObject.curvePoints = {
        endPoint: {
          x: clonedObject.curvePoints.endPoint.x + offset,
          y: clonedObject.curvePoints.endPoint.y + offset,
        },
        firstControlPoint: {
          x: clonedObject.curvePoints.firstControlPoint.x + offset,
          y: clonedObject.curvePoints.firstControlPoint.y + offset,
        },
        secondControlPoint: {
          x: clonedObject.curvePoints.secondControlPoint.x + offset,
          y: clonedObject.curvePoints.secondControlPoint.y + offset,
        },
        startPoint: {
          x: clonedObject.curvePoints.startPoint.x + offset,
          y: clonedObject.curvePoints.startPoint.y + offset,
        },
      };
    }
    element[`${focusedObject.type}s`].splice(
      focusedObject.index + 1,
      0,
      clonedObject,
    );

    setElement((prev) => ({
      ...prev,
      [`${focusedObject.type}s`]: element[`${focusedObject.type}s`],
    }));
  };

  const handleObjectChange = (dataKey, dataValue) => {
    if (dataKey === "delete") {
      objects.splice(focusedObject.index, 1);
      setFocusedObject({ index: null, type: "", animate: false });
    } else if (dataKey === "type") {
      if (focusedObject.type === OBJECT_TYPES.TARGET) {
        objects[focusedObject.index] = {
          ...object,
          [dataKey]: dataValue,
          size: object.size,
          objectPosition: {
            x: object.objectPosition.x,
            y: object.objectPosition.y,
          },
        };
      } else {
        if (dataValue === OBSTACLE_TYPES.CURVE.value) {
          objects[focusedObject.index] = {
            ...object,
            curvePoints: {
              startPoint: {
                x: object.objectPosition.x - 10,
                y: object.objectPosition.y,
              },
              firstControlPoint: {
                x: object.objectPosition.x - 10 + 7.33,
                y: object.objectPosition.y,
              },
              secondControlPoint: {
                x: object.objectPosition.x + 10 - 7.33,
                y: object.objectPosition.y,
              },
              endPoint: {
                x: object.objectPosition.x + 10,
                y: object.objectPosition.y,
              },
            },
            objectPosition: {
              x: object.objectPosition.x,
              y: object.objectPosition.y,
            },
            [dataKey]: dataValue,
          };
        } else {
          objects[focusedObject.index] = {
            ...object,
            ...DEFAULT_OBSTACLE_OBJECT_DATA,
            [dataKey]: dataValue,
            objectPosition: {
              x: object.objectPosition.x,
              y: object.objectPosition.y,
            },
          };
        }
      }
    } else {
      objects[focusedObject.index] = {
        ...object,
        [dataKey]: dataValue,
      };
    }
    setElement({ ...element });
  };

  const handleAnimation = (e) => {
    if (focusedObject.animate)
      setFocusedObject({ ...focusedObject, animate: false });
    if (
      e.target.name === "animationType" &&
      e.target.value === ANIMATION_TYPES.NONE
    ) {
      element.obstacles[focusedObject.index] = {
        ...object,
        [e.target.name]: e.target.value,
        animation: {
          ...object.animation,
          speed: 0,
          motionPoint: { x: 0, y: 0 },
          distance: { x: 0, y: 0 },
        },
      };
    } else if (
      e.target.name === "animationType" &&
      e.target.value !== ANIMATION_TYPES.NONE
    ) {
      const obstacle = element.obstacles[focusedObject.index];
      element.obstacles[focusedObject.index] = {
        ...object,
        [e.target.name]: e.target.value,
        animation: {
          motionPoint: {
            x: obstacle?.animation?.motionPoint?.x
              ? obstacle.animation.motionPoint.x
              : obstacle.objectPosition.x,
            y: obstacle?.animation?.motionPoint?.y
              ? obstacle.animation.motionPoint.y
              : obstacle.objectPosition.y,
          },
          distance: {
            x: obstacle?.animation?.distance?.x
              ? obstacle.animation.distance.x
              : 0,
            y: obstacle?.animation?.distance?.y
              ? obstacle.animation.distance.y
              : 0,
          },
          speed: object?.animation?.speed || 1,
        },
      };
    } else {
      element.obstacles[focusedObject.index] = {
        ...object,
        animation: {
          ...object.animation,
          [e.target.name]: e.target.value,
        },
      };
    }

    setElement({ ...element });
  };
  return (
    <div className="flex flex-col gap-4 rounded-lg bg-slate-50 p-4">
      <Tooltip target=".custom-tooltip" />
      <p className="relative text-center text-lg font-bold capitalize">
        {focusedObject.type} Settings
        <i
          data-pr-tooltip="Close"
          onClick={() =>
            setFocusedObject({ index: null, type: "", animate: false })
          }
          className="custom-tooltip pi pi-times absolute right-0 top-0 cursor-pointer py-2"
        />
      </p>
      {/*  */}
      <div className="grid grid-cols-12 items-center gap-4">
        <span className="col-span-4">Type</span>
        <Dropdown
          name="targetType"
          value={
            focusedObject.type === OBJECT_TYPES.TARGET
              ? object?.type
              : object?.type
          }
          options={
            focusedObject.type === OBJECT_TYPES.TARGET
              ? Object.values(TARGET_TYPES)
              : Object.values(OBSTACLE_TYPES)
          }
          onChange={(e) => {
            handleObjectChange("type", e.value);
          }}
          className="col-span-6"
        />
        <i
          onClick={handleClone}
          data-pr-tooltip="Clone"
          data-pr-position="top"
          className="custom-tooltip pi pi-copy col-span-1 cursor-pointer text-lg text-green-500"
        ></i>
        <i
          onClick={() => {
            handleObjectChange("delete");
          }}
          data-pr-tooltip="Delete"
          data-pr-position="top"
          className="custom-tooltip pi pi-trash col-span-1 cursor-pointer text-lg text-red-500"
        ></i>
      </div>

      {/* Color */}
      <div className="grid grid-cols-12 items-center gap-4">
        <span className="col-span-4">Fill</span>
        <ColorPaletteOverlay
          overlayRef={overlayRef}
          handleColorClick={(e, item) => {
            overlayRef.current.toggle(e);
            handleObjectChange("fill", item.hex);
          }}
        />
        <Button
          icon="pi pi-palette"
          onClick={(e) => overlayRef.current.toggle(e)}
          type="button"
          className="col-span-2"
        />
        <ColorPicker
          name="color"
          value={
            focusedObject.type === OBJECT_TYPES.TARGET
              ? object?.fill
              : object?.fill === "#00000000"
                ? "#ffffff"
                : object?.fill
          }
          onChange={(e) => {
            handleObjectChange("fill", "#" + e.target.value);
          }}
          disabled={object?.type === OBSTACLE_TYPES.CURVE.value}
          pt={{
            input: { className: "w-full p-6" },
          }}
          className={`${
            focusedObject.type === OBJECT_TYPES.TARGET
              ? "col-span-6 pl-1"
              : "col-span-5 pl-1"
          }`}
        />
        {focusedObject.type !== OBJECT_TYPES.TARGET && (
          <i
            data-pr-tooltip="Remove fill"
            onClick={
              object?.type === OBSTACLE_TYPES.CURVE.value
                ? () => {}
                : () => handleObjectChange("fill", "#00000000")
            }
            className={`custom-tooltip pi pi-times col-span-1 ${object?.type === OBSTACLE_TYPES.CURVE.value ? "" : "cursor-pointer"}`}
          />
        )}
      </div>

      {focusedObject.type &&
        (focusedObject.type === OBJECT_TYPES.TARGET ? (
          <TargetSettings
            target={object}
            handleObjectChange={handleObjectChange}
            scenesOptions={scenesOptions}
          />
        ) : (
          <ObstacleSettings
            obstacle={object}
            handleObjectChange={handleObjectChange}
            handleAnimation={handleAnimation}
          />
        ))}
    </div>
  );
};
