import React from "react";
import { Outlet } from "react-router-dom";

// Components
import { NavBar } from "../components/NavBar";

export const WithNav = () => {
  return (
    <div className="mx-8 h-full">
      <div className="fixed left-0 right-0 top-0 z-50 w-full">
        <NavBar />
      </div>
      <div className="mt-20 w-full p-5">
        <Outlet />
      </div>
    </div>
  );
};
