// Utilities
import { drawBezierCurve } from "./drawBezierCurve";
import { drawGap } from "./drawGap";
import { drawBump } from "./drawBump";
import { drawLine } from "./drawLine";

// Constants
import { LINE_TYPES } from "../constants/constants";

export const drawElement = (
  board,
  element,
  edit = false,
  setElement = () => {},
) => {
  switch (element.type) {
    case LINE_TYPES.FLAT_LINE.value: // FLAT LINE ELEMENT
      drawLine(board, element, edit, setElement);
      break;
    case LINE_TYPES.GAP.value: // GAP ELEMENT
      drawGap(board, element, edit, setElement);
      break;
    case LINE_TYPES.BUMP_CURVE.value: // BUMP CURVE ELEMENT
      drawBump(board, element, edit, setElement);
      break;
    case LINE_TYPES.DOWNHILL_CURVE.value: // DOWNHILL CURVE ELEMENT
    case LINE_TYPES.UPHILL_CURVE.value: // UPHILL CURVE ELEMENT
    case LINE_TYPES.FLAT_CURVE.value: // FLAT Curve ELEMENT
    case 6: // TRANSITION CURVE ELEMENT
      drawBezierCurve(board, element, edit, setElement);
      break;
    default:
      break;
  }
};
