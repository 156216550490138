import React, { memo } from "react";

// Prime React Component
import { ProgressSpinner } from "primereact/progressspinner";

export const Loading = memo(function Loading() {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <ProgressSpinner
        pt={{
          circle: {
            style: {
              stroke: "#0996d1",
              strokeWidth: 3,
              animation: "none",
            },
          },
        }}
        style={{ width: "30px", height: "30px" }}
        strokeWidth="4"
      />
    </div>
  );
});
