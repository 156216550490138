import React from "react";

// Prime React Components
import { InputText } from "primereact/inputtext";

export const SearchInput = ({ onGlobalFilterChange, globalFilterValue }) => {
  return (
    <div>
      <span className="p-input-icon-left min-w-[300px]">
        <i className="pi pi-search" />
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Search scene title"
        />
      </span>
    </div>
  );
};
