// Constants
import { OBSTACLE_TYPES } from "../constants/constants";

// Utilities
import { calculateMidPoint } from "./calculateMidPoint";
import { getDraggedItem, setDraggedItem } from "./draggedItem";
import { pointOptions } from "./pointUtils";

export const setTranslateToPoint = (
  board,
  obstacle,
  element,
  setElement,
  index,
) => {
  const translationPointOptions = {
    name: "Translate To Point",
    label: { color: "red" },
    size: 4,
    color: "green",
    fixed: false,
    visible: true,
    ...pointOptions,
  };

  const translateToPoint = board.create(
    "point",
    [obstacle.animation.motionPoint.x, obstacle.animation.motionPoint.y],
    translationPointOptions,
  );
  setDraggedItem(translateToPoint);
  translateToPoint.on("up", (e) => {
    if (getDraggedItem() !== translateToPoint.id) return;
    const updatedPoints = [...element.obstacles];
    updatedPoints[index] = {
      ...updatedPoints[index],
      animation: {
        ...updatedPoints[index].animation,
        motionPoint: { x: translateToPoint.X(), y: translateToPoint.Y() },
        distance: {
          x:
            obstacle.type !== OBSTACLE_TYPES.CURVE.value
              ? translateToPoint.X() - obstacle.objectPosition.x
              : translateToPoint.X() -
                calculateMidPoint(obstacle.curvePoints).x,
          y:
            obstacle.type !== OBSTACLE_TYPES.CURVE.value
              ? translateToPoint.Y() - obstacle.objectPosition.y
              : translateToPoint.Y() -
                calculateMidPoint(obstacle.curvePoints).y,
        },
      },
    };

    setElement({ ...element, obstacles: updatedPoints });
  });
};
