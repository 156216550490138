import { useEffect } from "react";
import JXG from "jsxgraph";

// Store
import { useObjectStore, useScenesStore } from "../store/store.js";

// Utilities
import { drawElement } from "../utilities/drawElement.js";
import { drawStartPoint } from "../utilities/drawStartPoint.js";
import { initializeBoard } from "../utilities/initializeBoard.js";
import { drawBackground } from "../utilities/drawBackground.js";
import { setBoardDimensions } from "../utilities/setBoardDimensions.js";
import { createSVGImage } from "../utilities/createSvgImage.js";
import { createTarget } from "../utilities/createTarget.js";
import { createObstacle } from "../utilities/createObstacle.js";

// Constants
import { START_POINT_SVG, TARGET_TYPES } from "../constants/constants.js";
import { useParams } from "react-router-dom";

let boardData = {
  zoom: { x: 1, y: 1 },
  originCoords: null,
  prevRefId: null,
};
let sceneData = {
  zoom: { x: 1, y: 1 },
  originCoords: null,
};

const animate = { rotate: null, translate: null };
if (animate.rotate) cancelAnimationFrame(animate.rotate);
if (animate.translate) clearTimeout(animate.translate);

// Build Element Board
export const useElementBoard = (
  htmlElementId,
  element,
  initialDim,
  scenesOptions,
  navigate = false,
  edit = false,
  setElement = () => {},
) => {
  const { sceneId } = useParams();
  const scene = useScenesStore((state) => state.scene);
  const { focusedObject, setFocusedObject } = useObjectStore();
  // Set Dimensions
  const dim = setBoardDimensions(initialDim);
  useEffect(() => {
    // Initialize Board
    const board = initializeBoard(htmlElementId, dim, navigate);

    if (
      edit &&
      boardData.originCoords !== null &&
      boardData.prevRefId === htmlElementId
    ) {
      board.setZoom(boardData.zoom.x, boardData.zoom.y);
      board.moveOrigin(boardData.originCoords.x, boardData.originCoords.y);
    }

    // Draw Chain
    drawElement(board, element, edit, setElement);
    // Draw Targets
    element.targets &&
      element.targets.forEach((target, index) => {
        if (scenesOptions && target.type === TARGET_TYPES.FLAG.value) {
          if (
            scenesOptions.every((option) => option.id !== target?.nextScene)
          ) {
            const updatedPoints = [...element.targets];
            const currentSceneId = sceneId;
            updatedPoints[index] = {
              ...updatedPoints[index],
              nextScene: currentSceneId,
            };
            setElement({ ...element, targets: updatedPoints });
          }
        }
        createTarget(
          board,
          element,
          target,
          focusedObject,
          index,
          edit,
          setElement,
          setFocusedObject,
        );
      });
    // Draw Obstacles
    element.obstacles &&
      element.obstacles.forEach((obstacle, index) => {
        createObstacle(
          board,
          element,
          obstacle,
          focusedObject,
          index,
          edit,
          setElement,
          setFocusedObject,
          animate,
        );
      });

    return () => {
      if (edit) {
        boardData = {
          prevRefId: htmlElementId,
          zoom: { x: board.zoomX, y: board.zoomY },
          originCoords: {
            x: board.origin.scrCoords[1],
            y: board.origin.scrCoords[2],
          },
        };
      }
      JXG.JSXGraph.freeBoard(board);
    };
  }, [element, focusedObject?.index, focusedObject?.animate]);
};

// Build Scene Board
export const useSceneBoard = (
  htmlElementId,
  absScene,
  initialDim,
  editStartingPoint = false,
  scene,
  setScene = () => {},
) => {
  // Set Dimensions
  const dim = setBoardDimensions(initialDim);
  const image = createSVGImage(START_POINT_SVG);

  useEffect(() => {
    // Initialize Board
    const board = initializeBoard(htmlElementId, dim, true);

    if (editStartingPoint && sceneData.originCoords !== null) {
      board.setZoom(sceneData.zoom.x, sceneData.zoom.y);
      board.moveOrigin(sceneData.originCoords.x, sceneData.originCoords.y);
    }

    if (absScene?.bgImage && absScene.bgImage !== "None") {
      drawBackground(board, absScene.bgImage, dim, absScene.scale);
    }

    // Draw Chain
    absScene?.chains.forEach((chain) =>
      chain.elements.forEach((element) => {
        drawElement(board, element, false);
        // Draw Targets
        element.targets &&
          element.targets.forEach((target) =>
            createTarget(board, element, target),
          );
        // Draw Obstacles
        element.obstacles &&
          element.obstacles.forEach((obstacle) =>
            createObstacle(board, element, obstacle),
          );
      }),
    );

    drawStartPoint(board, absScene, image, editStartingPoint, scene, setScene);

    return () => {
      if (editStartingPoint) {
        sceneData = {
          zoom: { x: board.zoomX, y: board.zoomY },
          originCoords: {
            x: board.origin.scrCoords[1],
            y: board.origin.scrCoords[2],
          },
        };
      }
      JXG.JSXGraph.freeBoard(board);
    };
  }, [htmlElementId, absScene, dim, editStartingPoint]);
};
