// Constants
import { CURVE_TYPES } from "../constants/constants";

export const getElementAngle = (element, firstPoint, secondPoint) => {
  let angle;
  if (CURVE_TYPES.includes(element.type)) {
    angle = getCurveAngle(firstPoint, secondPoint);
  } else {
    if (element.rippleDensity && element.rippleHeight) {
      angle = Math.atan(
        (element.rippleHeight * 2 * Math.PI) /
          (element.points.endPoint.x / element.rippleDensity),
      );
    } else {
      angle = 0;
    }
  }
  return angle;
};

const getCurveAngle = (firstPoint, secondPoint) => {
  let angle;
  if (secondPoint.y - firstPoint.y === 0) {
    if (secondPoint.x - firstPoint.x >= 0) {
      angle = 0;
    } else {
      angle = Math.PI;
    }
  } else if (secondPoint.x - firstPoint.x === 0) {
    if (secondPoint.y - firstPoint.y >= 0) {
      angle = Math.PI / 2;
    } else {
      angle = Math.PI * 1.5;
    }
  } else {
    angle = Math.atan(
      (secondPoint.y - firstPoint.y) / (secondPoint.x - firstPoint.x),
    );

    if (secondPoint.x - firstPoint.x >= 0) {
      if (secondPoint.y - firstPoint.y >= 0) {
        angle;
      } else {
        angle += 2 * Math.PI;
      }
    } else {
      if (secondPoint.y - firstPoint.y >= 0) {
        angle += Math.PI;
      } else {
        angle -= Math.PI;
      }
    }
  }
  return angle;
};
