import React, { useRef, useState } from "react";

// Store
import { useObjectStore, useScenesStore } from "../store/store";

// Constants
import {
  LINE_TYPES,
  DEFAULT_ELEMENT_DATA,
  DEFAULT_FLAT_LINE_DATA,
  DEFAULT_DOWNHILL_CURVE_DATA,
  DEFAULT_UPHILL_CURVE_DATA,
  DEFAULT_BUMP_CURVE_DATA,
  DEFAULT_FLAT_CURVE_DATA,
  TARGET_TYPES,
  OBSTACLE_TYPES,
  DEFAULT_OBJECT_DATA,
  DEFAULT_OBSTACLE_DATA,
  DEFAULT_OBSTACLE_CURVE_DATA,
  DEFAULT_OBSTACLE_OBJECT_DATA,
  DEFAULT_TARGET_DATA,
  DEFAULT_TARGET_FLAG_DATA,
  DEFAULT_TARGET_POINT_DATA,
  DEFAULT_GAP_DATA,
  DEFAULT_FALLING_ELEMENT_DATA,
} from "../constants/constants";

// Components
import { PreviewElement } from "./PreviewElement";
import { ObjectSettings } from "./ObjectSettings";

// Prime react components
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";
import { InputSwitch } from "primereact/inputswitch";
import ColorPaletteOverlay from "./ColorPaletteOverlay";

export const Modal = ({
  chainIndex,
  elementIndex,
  elementToUpdate,
  setModal,
  scenesOptions,
}) => {
  const overlayRef = useRef();
  const scene = useScenesStore((state) => state.scene);
  const addElement = useScenesStore((state) => state.addElement);
  const updateElement = useScenesStore((state) => state.updateElement);
  const [element, setElement] = useState(
    elementToUpdate || { ...DEFAULT_ELEMENT_DATA, ...DEFAULT_FLAT_LINE_DATA },
  );
  const [targetType, setTargetType] = useState(TARGET_TYPES.COIN.value);
  const [obstacleType, setObstacleType] = useState(
    OBSTACLE_TYPES.ELLIPSE.value,
  );
  const { focusedObject, setFocusedObject } = useObjectStore();

  // Update element data
  const handleChange = (e) => {
    if (e.target.name === "type") {
      switch (e.target.value) {
        case LINE_TYPES.FLAT_LINE.value: // FLAT LINE ELEMENT
          setElement({ ...element, ...DEFAULT_FLAT_LINE_DATA });
          break;
        case LINE_TYPES.GAP.value: // GAP ELEMENT
          setElement({ ...element, ...DEFAULT_GAP_DATA });
          break;
        case LINE_TYPES.DOWNHILL_CURVE.value: // DOWNHILL CURVE ELEMENT
          setElement({ ...element, ...DEFAULT_DOWNHILL_CURVE_DATA });
          break;
        case LINE_TYPES.UPHILL_CURVE.value: // UPHILL CURVE ELEMENT
          setElement({ ...element, ...DEFAULT_UPHILL_CURVE_DATA });
          break;
        case LINE_TYPES.BUMP_CURVE.value: // BUMP CURVE ELEMENT
          setElement({ ...element, ...DEFAULT_BUMP_CURVE_DATA });
          break;
        case LINE_TYPES.FLAT_CURVE.value: //  FLAT CURVE ELEMENT
          setElement({ ...element, ...DEFAULT_FLAT_CURVE_DATA });
          break;
        default:
          break;
      }
    } else if (e.target.name === "isFalling") {
      if (e.target.value) {
        setElement({
          ...element,
          [e.target.name]: e.target.value,
          falling: DEFAULT_FALLING_ELEMENT_DATA,
        });
      } else {
        const { fallingElement, ...otherAttributes } = element;
        setElement({
          ...otherAttributes,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "color") {
      setElement((prevState) => {
        return { ...prevState, [e.target.name]: `#${e.target.value}` };
      });
    } else {
      setElement({ ...element, [e.target.name]: e.target.value });
    }
  };

  const handleFallChange = (e) => {
    setElement({
      ...element,
      falling: {
        ...element.falling,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFocusedObject({ index: null, type: "", animate: false });
    elementToUpdate
      ? updateElement(element, chainIndex, elementIndex)
      : addElement(element, chainIndex, elementIndex);
    setModal({
      show: false,
      chainIndex: 0,
      elementIndex: 0,
      element: null,
    });
  };

  const addNewTarget = () => {
    const targets = element.targets;
    const lastTarget = targets && targets[targets.length - 1];

    let newTarget = {
      ...DEFAULT_OBJECT_DATA,
      ...DEFAULT_TARGET_DATA,
      type: targetType,
    };

    if (targetType === TARGET_TYPES.FLAG.value) {
      const splittedUrl = window.location.pathname.split("/");
      newTarget = {
        ...newTarget,
        ...DEFAULT_TARGET_FLAG_DATA,
        nextScene: splittedUrl[splittedUrl.length - 1],
      };
    } else {
      newTarget = { ...newTarget, ...DEFAULT_TARGET_POINT_DATA };
    }

    if (targets.length) {
      newTarget = {
        ...newTarget,
        objectPosition: {
          x: lastTarget.objectPosition.x + lastTarget.size / 2 + 20,
          y: lastTarget.objectPosition.y,
        },
      };
    }

    setElement({ ...element, targets: [...targets, newTarget] });
  };

  const addNewObstacle = () => {
    const obstaclePoints = element.obstacles;
    const lastObstacle =
      obstaclePoints && obstaclePoints[obstaclePoints.length - 1];

    let newObstacle = {
      ...DEFAULT_OBJECT_DATA,
      ...DEFAULT_OBSTACLE_DATA,
      type: obstacleType,
    };

    if (obstacleType === OBSTACLE_TYPES.CURVE.value) {
      newObstacle = { ...newObstacle, ...DEFAULT_OBSTACLE_CURVE_DATA };
      if (obstaclePoints.length) {
        if (lastObstacle.type === OBSTACLE_TYPES.CURVE.value) {
          newObstacle = {
            ...newObstacle,
            curvePoints: {
              startPoint: {
                x: lastObstacle.curvePoints.endPoint.x + 20,
                y: lastObstacle.curvePoints.endPoint.y,
              },
              firstControlPoint: {
                x: lastObstacle.curvePoints.endPoint.x + 27.33,
                y: lastObstacle.curvePoints.endPoint.y,
              },
              secondControlPoint: {
                x: lastObstacle.curvePoints.endPoint.x + 34.66,
                y: lastObstacle.curvePoints.endPoint.y,
              },
              endPoint: {
                x: lastObstacle.curvePoints.endPoint.x + 40,
                y: lastObstacle.curvePoints.endPoint.y,
              },
            },
            objectPosition: {
              x: lastObstacle.curvePoints.endPoint.x + 30,
              y: lastObstacle.curvePoints.endPoint.y,
            },
          };
        } else {
          newObstacle = {
            ...newObstacle,
            curvePoints: {
              startPoint: {
                x: lastObstacle.objectPosition.x + lastObstacle.width / 2 + 10,
                y: lastObstacle.objectPosition.y,
              },
              firstControlPoint: {
                x:
                  lastObstacle.objectPosition.x +
                  lastObstacle.width / 2 +
                  17.33,
                y: lastObstacle.objectPosition.y,
              },
              secondControlPoint: {
                x:
                  lastObstacle.objectPosition.x +
                  lastObstacle.width / 2 +
                  24.66,
                y: lastObstacle.objectPosition.y,
              },
              endPoint: {
                x: lastObstacle.objectPosition.x + lastObstacle.width / 2 + 30,
                y: lastObstacle.objectPosition.y,
              },
            },
            objectPosition: {
              x: lastObstacle.objectPosition.x + lastObstacle.width / 2 + 20,
              y: lastObstacle.objectPosition.y,
            },
          };
        }
      }
    } else {
      newObstacle = { ...newObstacle, ...DEFAULT_OBSTACLE_OBJECT_DATA };
      if (obstaclePoints.length) {
        if (lastObstacle.type === OBSTACLE_TYPES.CURVE.value) {
          newObstacle = {
            ...newObstacle,
            objectPosition: {
              x: lastObstacle.curvePoints.endPoint.x + 20,
              y: lastObstacle.curvePoints.endPoint.y,
            },
          };
        } else {
          newObstacle = {
            ...newObstacle,
            objectPosition: {
              x: lastObstacle.objectPosition.x + lastObstacle.width / 2 + 20,
              y: lastObstacle.objectPosition.y,
            },
          };
        }
      }
    }

    setElement({ ...element, obstacles: [...obstaclePoints, newObstacle] });
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className="flex h-full items-center gap-3"
    >
      {/* Graph */}
      <div className="relative flex size-3/5 items-center gap-3">
        {/* Tips */}
        <div className="absolute bottom-2 left-2 rounded-lg bg-gray-600 p-2">
          <p className="text-white">
            Right-click the object to view its properties.
          </p>
        </div>
        <PreviewElement
          element={element}
          elementIndex={elementIndex}
          chainIndex={chainIndex}
          scenesOptions={scenesOptions}
          navigate={true}
          edit={true}
          setElement={setElement}
        />
      </div>
      {/* Form */}
      <div className="relative flex h-[75vh] flex-1 flex-col justify-between gap-3 overflow-y-auto overflow-x-hidden pr-2">
        {/* Form Inputs */}
        <div className="mt-2 flex flex-col gap-3">
          {/* Line Type */}
          <div className="grid grid-cols-12  items-center gap-3">
            <label className="col-span-4">Type</label>
            <Dropdown
              name="type"
              value={element.type}
              options={Object.values(LINE_TYPES)}
              optionValue="value"
              optionLabel="label"
              onChange={(e) => handleChange(e)}
              className="col-span-8"
            />
          </div>
          {/* Falling Flag */}
          <div className="grid grid-cols-12  items-center gap-3">
            <label className="col-span-4">Falling Element</label>
            <InputSwitch
              name="isFalling"
              checked={element.isFalling}
              onChange={(e) => handleChange(e)}
              className="col-span-8"
            />
          </div>
          <div
            className={`[transition-behavior: allow-discrete] flex origin-top flex-col gap-3 overflow-hidden rounded-lg bg-slate-50 p-4 transition-all duration-500 ease-in-out ${element.isFalling ? "visible h-full scale-y-100 opacity-100" : "collapse h-0 scale-y-0 opacity-0"}`}
          >
            {/* Delay Time */}
            <div className="grid grid-cols-12 items-center gap-3">
              <label className="col-span-4 leading-4">
                Fall Delay{" "}
                <span className="whitespace-nowrap text-[10px]">
                  ( second )
                </span>
              </label>

              <InputText
                keyfilter="num"
                name="fallDelay"
                value={element?.falling?.fallDelay}
                onChange={(e) => handleFallChange(e)}
                className="col-span-8"
                disabled={!element.isFalling}
              />
            </div>
            {/* Regenerate Flag */}
            <div className="grid grid-cols-12  items-center gap-3">
              <label className="col-span-4">Regenerate Element</label>
              <InputSwitch
                name="regenerate"
                checked={element?.falling?.regenerate}
                onChange={(e) => handleFallChange(e)}
                className="col-span-8"
                disabled={!element.isFalling}
              />
            </div>
            {/* Regenerate Delay */}
            <div className="grid grid-cols-12  items-center gap-3">
              <label className="col-span-4 leading-4">
                Regenerate Delay{" "}
                <span className="whitespace-nowrap text-[10px]">
                  ( second )
                </span>
              </label>
              <InputText
                keyfilter="num"
                name="regenerateDelay"
                value={element?.falling?.regenerateDelay}
                onChange={(e) => handleFallChange(e)}
                className="col-span-8"
                disabled={!element.isFalling || !element?.falling?.regenerate}
              />
            </div>
          </div>

          {/* Line Color */}
          <div className="grid grid-cols-12  items-center gap-3">
            <label className="col-span-4">Color</label>
            <div className="col-span-8 grid grid-cols-8 items-center gap-2">
              <ColorPaletteOverlay
                overlayRef={overlayRef}
                handleColorClick={(e, item) => {
                  overlayRef.current.toggle(e);
                  setElement((prevState) => {
                    return { ...prevState, color: item.hex };
                  });
                }}
              />
              <Button
                icon="pi pi-palette"
                type="button"
                className="col-span-2"
                onClick={(e) => {
                  overlayRef.current.toggle(e);
                }}
              />
              <ColorPicker
                name="color"
                value={element.color}
                onChange={(e) => handleChange(e)}
                pt={{
                  input: { className: "w-full p-6" },
                }}
                className="col-span-6"
              />
            </div>
          </div>
          {/* Line Width */}
          <div className="grid grid-cols-12  items-center gap-3">
            <label className="col-span-4">Line Width</label>
            <InputText
              keyfilter="num"
              name="lineWidth"
              value={element.lineWidth}
              onChange={(e) => handleChange(e)}
              className="col-span-8"
            />
          </div>
          {/* Friction */}
          <div className="grid grid-cols-12  items-center gap-3">
            <label className="col-span-4">Friction</label>
            <InputText
              keyfilter="num"
              name="friction"
              value={element.friction}
              onChange={(e) => handleChange(e)}
              className="col-span-8"
            />
          </div>
          {/* Restitution */}
          <div className="grid grid-cols-12  items-center gap-3">
            <label className="col-span-4">Restitution</label>
            <InputText
              keyfilter="num"
              name="restitution"
              value={element.restitution}
              onChange={(e) => handleChange(e)}
              className="col-span-8"
            />
          </div>
          {/* Targets */}
          <div className="grid grid-cols-12  items-center gap-3">
            <label className="col-span-4">Add Target</label>
            <div className="col-span-8 flex items-center gap-3">
              <Dropdown
                name="targetType"
                value={targetType}
                options={Object.values(TARGET_TYPES)}
                onChange={(e) => {
                  setTargetType(e.value);
                }}
                className="flex-1"
              />
              <Button type="button" onClick={addNewTarget} icon="pi pi-plus" />
            </div>
          </div>
          {/* Obstacles */}
          <div className="grid grid-cols-12  items-center gap-3">
            <label className="col-span-4">Add Obstacle</label>
            <div className="col-span-8 flex items-center gap-3">
              <Dropdown
                name="targetType"
                value={obstacleType}
                options={Object.values(OBSTACLE_TYPES)}
                onChange={(e) => {
                  setObstacleType(e.value);
                }}
                className="flex-1"
              />
              <Button
                type="button"
                onClick={addNewObstacle}
                icon="pi pi-plus"
              />
            </div>
          </div>

          {/* Configure Target/Obstacle */}
          {focusedObject.index !== null && (
            <ObjectSettings
              element={element}
              setElement={setElement}
              scenesOptions={scenesOptions}
            />
          )}
        </div>

        {/* Form Action Buttons */}
        <div className="sticky bottom-0 right-0 z-10 flex w-full justify-end gap-3 bg-white p-2">
          <Button
            label="Cancel"
            severity="danger"
            type="button"
            onClick={() => {
              setFocusedObject({ index: null, type: "", animate: false });
              setModal({
                show: false,
                chainIndex: 0,
                elementIndex: 0,
                element: null,
              });
            }}
          />
          <Button label="Submit" severity="success" />
        </div>
      </div>
    </form>
  );
};
