import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

// Store
import { useObjectStore, useScenesStore } from "../store/store";

// Services
import {
  fetchMarbleScene,
  fetchMarbleScenes,
  updateMarbleScene,
} from "../services/marbleScenes";

// Utilities
import { absoluteToRelative } from "../utilities/dataConverter";
import { buildScene } from "../utilities/buildScene";

//Constants
import {
  BACKGROUND_IMAGES,
  DEFAULT_BALL_DIAMETER,
  BALL_SCREEN_INITIAL_SIZE_PERCENTAGE,
  TRANSITION_ELEMENT_INITIAL_RADIUS,
  SCENE_STATES,
} from "../constants/constants";

// Pages
import { Loading } from "./Loading";

// Components
import { Modal } from "../components/Modal";
import { PreviewScene } from "../components/PreviewScene";
import { Chain } from "../components/Chain";

// Prime React Components
import { Dropdown } from "primereact/dropdown";
import { ListBox } from "primereact/listbox";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { pointOptions } from "../utilities/pointUtils";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

export const Scene = () => {
  // let activated = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { sceneId } = useParams();
  const { setFocusedObject } = useObjectStore();
  const scene = useScenesStore((state) => state.scene);
  const setScene = useScenesStore((state) => state.setScene);
  const [editScene, setEditScene] = useState(false);
  const [scenesOptions, setScenesOptions] = useState([]);
  const [previewScene, setPreviewScene] = useState(false);
  const [saveToIdDropdown, setSaveToIdDropdown] = useState({
    saveToId: sceneId,
    isOpen: false,
  });
  const [modal, setModal] = useState({
    show: false,
    chainIndex: 0,
    elementIndex: 0,
    element: null,
  });

  const { data, isLoading } = useQuery({
    queryKey: ["scenes", sceneId],
    queryFn: () => fetchMarbleScene(sceneId),
    select: useCallback((data) => absoluteToRelative(data), []),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const scenesQuery = useQuery({
    queryKey: ["scenes"],
    queryFn: fetchMarbleScenes,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (location.state) {
      const relativeScene = absoluteToRelative(location.state.duplicateScene);
      setScene(relativeScene);
    } else {
      setScene(data);
    }
  }, [data, location.state]);

  useEffect(() => {
    const allScenes = [
      {
        id: sceneId,
        title: "Current Scene",
      },
    ];

    if (scenesQuery.data) {
      scenesQuery.data.forEach((option) => {
        if (option.id !== sceneId)
          allScenes.push({
            id: option.id,
            title: option.data.title,
          });
      });
    }

    if (allScenes.every((scene) => scene.id !== sceneId)) {
      allScenes.unshift({
        id: sceneId,
        title: "Current Scene",
      });
    }

    setScenesOptions(allScenes);
  }, [scenesQuery.data]);

  const absoluteScene = useMemo(() => scene && buildScene(scene), [scene]);

  const updateSceneMutation = useMutation({
    mutationFn: ({ saveToId, absoluteScene }) => {
      updateMarbleScene(sceneId, {
        ...absoluteScene,
        lastEditedAt: new Date(),
      });

      if (saveToId !== sceneId) {
        fetchMarbleScene(saveToId)
          .then((saveToScene) => {
            updateMarbleScene(saveToId, {
              ...absoluteScene,
              title: saveToScene.title,
              createdAt: saveToScene.createdAt,
              state: saveToScene.state,
              lastEditedAt: new Date(),
            });
          })
          .catch((error) => console.error(error));
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["scenes", sceneId],
      });
      navigate(-1);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const getSceneTitleById = (id) => {
    const scene = scenesOptions.find((item) => item.id === id);
    return scene ? scene.title : "Title not found";
  };

  // if (data && !activated.current) {
  //   activated.current = true;
  //   setScene(data);
  // }
  if (isLoading) return <Loading />;

  return (
    <div className="relative">
      <ConfirmDialog />
      {/* Preview Scene Action Button */}
      <Button
        data-pr-tooltip="Preview"
        onClick={() => setPreviewScene(true)}
        pt={{ label: { style: { display: "none" } } }}
        className="custom-tooltip fixed right-20 top-[6.25rem] h-[3.125rem] w-[3.125rem] p-4"
        icon="pi pi-eye"
      />

      {/* Scene Modal */}
      <Dialog
        header={scene?.title}
        visible={previewScene}
        blockScroll
        onHide={() => setPreviewScene(false)}
        className={`${
          window.innerWidth > window.innerHeight
            ? "h-[90vh] w-[80vh]"
            : "h-[90vw] w-[80vw]"
        }`}
        pt={{
          content: { className: "overflow-hidden" },
        }}
      >
        <PreviewScene
          absoluteScene={absoluteScene}
          scenesOptions={scenesOptions}
          editStartingPoint={true}
          setScene={setScene}
        />
      </Dialog>

      {/* Element Modal */}
      <Dialog
        header={scene?.title}
        visible={modal.show}
        blockScroll
        onHide={() => {
          setFocusedObject({ index: null, type: "", animate: false });
          setModal({ ...modal, show: false });
        }}
        className={`${
          window.innerWidth > window.innerHeight ? "w-[120vh]" : "w-[80vw]"
        }`}
        pt={{
          content: { className: "overflow-hidden" },
        }}
      >
        <Modal
          chainIndex={modal.chainIndex}
          elementIndex={modal.elementIndex}
          elementToUpdate={modal.element}
          setModal={setModal}
          scenesOptions={scenesOptions}
        />
      </Dialog>

      {/* Scene */}
      <div className="flex w-full gap-12 px-24 py-0">
        <div className="flex w-full flex-col items-center gap-3">
          {/* Scene Header */}
          {editScene ? (
            <EditSceneSpecsTemplate setEditScene={setEditScene} />
          ) : (
            <SceneSpecsTemplate setEditScene={setEditScene} />
          )}
          {/* Scene Body */}
          <div className="flex w-full flex-col gap-3 rounded-lg border border-slate-900 p-4">
            {/* Chain Sort Buttons Tooltip */}
            <Tooltip target=".custom-tooltip" position="top" showDelay={700} />
            {/* Chains Map */}
            {scene?.chains.map((chain, chainIndex) => (
              <div
                key={chainIndex}
                className="flex flex-col gap-3 rounded-lg border border-slate-900 p-4"
              >
                <Chain chainIndex={chainIndex} setModal={setModal} />
              </div>
            ))}
            {/* Scene Action Buttons */}
            <div className="flex justify-end gap-3">
              <Button
                label="Cancel"
                severity="danger"
                type="button"
                onClick={() => navigate(-1)}
              />
              <div className="flex">
                <Button
                  label="Save changes"
                  severity="success"
                  onClick={() => {
                    const saveScene = () => {
                      updateSceneMutation.mutate({
                        saveToId: saveToIdDropdown.saveToId,
                        absoluteScene,
                      });
                    };

                    if (sceneId !== saveToIdDropdown.saveToId) {
                      confirmDialog({
                        message: (
                          <p>
                            Are you sure you want to save changes to the scene{" "}
                            <strong>
                              {getSceneTitleById(saveToIdDropdown.saveToId)}
                            </strong>
                            ? This action will overwrite any existing data.
                          </p>
                        ),
                        header: "Confirmation",
                        className: "max-w-[95vw] sm:max-w-[50vw]",
                        icon: "pi pi-exclamation-triangle",
                        accept: saveScene,
                      });
                    } else {
                      saveScene();
                    }
                  }}
                  className="rounded-r-none shadow-none"
                />
                <div className="relative">
                  <Button
                    icon={`pi ${saveToIdDropdown.isOpen ? "pi-angle-up" : "pi-angle-down"}`}
                    severity="success"
                    onClick={() =>
                      setSaveToIdDropdown({
                        ...saveToIdDropdown,
                        isOpen: !saveToIdDropdown.isOpen,
                      })
                    }
                    className="rounded-l-none px-1 text-center shadow-none"
                  />
                  {saveToIdDropdown.isOpen ? (
                    <div className="absolute bottom-16 right-0 max-h-80 w-72 divide-y-2 overflow-y-auto rounded-md border border-slate-700 bg-white">
                      <p className="bg-slate-200 px-4 py-2 font-bold">
                        Save to Scene
                      </p>
                      <ul className="divide-y">
                        {scenesOptions.map((scene) => {
                          return (
                            <li
                              key={scene.id}
                              onClick={() =>
                                setSaveToIdDropdown({
                                  saveToId: scene.id,
                                  isOpen: false,
                                })
                              }
                              className="flex items-center justify-between gap-4 px-6 py-2 hover:bg-slate-100"
                            >
                              <span>{scene.title}</span>

                              {saveToIdDropdown.saveToId === scene.id ? (
                                <i className="pi pi-check" />
                              ) : null}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SceneSpecsTemplate = ({ setEditScene }) => {
  const scene = useScenesStore((state) => state.scene);
  const state = Object.keys(SCENE_STATES).find(
    (key) => SCENE_STATES[key].value === scene?.state,
  );
  return (
    <div className="align-center mb-2 flex min-h-24 w-full justify-between gap-3">
      <div className="flex flex-1 items-center justify-between gap-6">
        <div>
          <div className="mb-4 flex items-center justify-between gap-4">
            <h1 className="w-fit text-5xl">{scene?.title}</h1>
            {state ? (
              <Tag
                rounded
                severity={SCENE_STATES[state].severity}
                value={SCENE_STATES[state].label}
              />
            ) : null}
          </div>
          <div className="pl-8">
            <p className="w-fit">
              Scale: {scene?.scale?.x || 1} : {scene?.scale?.y || 1}
            </p>
            <p className="w-fit">
              Ball Diameter:&nbsp;
              {scene?.ballDiameter || DEFAULT_BALL_DIAMETER}
              &nbsp;units
            </p>
            <p className="w-fit">
              Ball Diameter to Screen Width Percentage:&nbsp;
              {scene?.ballScreenSizePercentage ||
                BALL_SCREEN_INITIAL_SIZE_PERCENTAGE}
              &#37;
            </p>
            <p className="w-fit">
              Transition Element Radius:&nbsp;
              {scene?.transitionElementRadius ||
                TRANSITION_ELEMENT_INITIAL_RADIUS}
              &nbsp;units
            </p>
          </div>
        </div>
        {scene?.bgImage && scene.bgImage !== "None" && (
          <img
            src={
              BACKGROUND_IMAGES.find((img) => img.value === scene.bgImage)
                .images[0]
            }
            alt={scene.bgImage}
            className="h-full w-52 rounded-lg object-cover"
          />
        )}
      </div>
      <div>
        <Button
          onClick={() => setEditScene(true)}
          severity="info"
          label="Edit Scene"
          className="whitespace-nowrap"
        />
      </div>
    </div>
  );
};

const EditSceneSpecsTemplate = ({ setEditScene }) => {
  const scene = useScenesStore((state) => state.scene);
  const setScene = useScenesStore((state) => state.setScene);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: scene?.title || "",
      state: scene?.state || SCENE_STATES.DEVELOPMENT.value,
      scaleX: scene?.scale?.x || 1,
      scaleY: scene?.scale?.y || 1,
      snapToGrid: pointOptions.snapToGrid,
      ballDiameter: scene?.ballDiameter || DEFAULT_BALL_DIAMETER,
      ballScreenSizePercentage:
        scene?.ballScreenSizePercentage || BALL_SCREEN_INITIAL_SIZE_PERCENTAGE,
      transitionElementRadius:
        scene?.transitionElementRadius || TRANSITION_ELEMENT_INITIAL_RADIUS,
      bgImage: scene?.bgImage || "",
    },
    values: {
      title: scene?.title,
      state: scene?.state || SCENE_STATES.DEVELOPMENT.value,
      scaleX: scene?.scale?.x || 1,
      scaleY: scene?.scale?.y || 1,
      snapToGrid: pointOptions.snapToGrid,
      ballDiameter: scene?.ballDiameter || DEFAULT_BALL_DIAMETER,
      ballScreenSizePercentage:
        scene?.ballScreenSizePercentage || BALL_SCREEN_INITIAL_SIZE_PERCENTAGE,
      transitionElementRadius:
        scene?.transitionElementRadius || TRANSITION_ELEMENT_INITIAL_RADIUS,
      bgImage: scene?.bgImage,
    },
  });

  const handleEditScene = (data) => {
    setScene({
      ...scene,
      title: data.title || scene?.id,
      state: data.state || scene.state,
      scale: { x: Number(data.scaleX) || 1, y: Number(data.scaleY) || 1 },
      ballDiameter: Number(data.ballDiameter),
      ballScreenSizePercentage: Number(data.ballScreenSizePercentage),
      transitionElementRadius: Number(data.transitionElementRadius),
      bgImage: data.bgImage || "",
    });
    reset({
      title: data.title || scene?.id,
      state: data.state || scene.state,
      scaleX: data.scaleX || 1,
      scaleY: data.ScaleY || 1,
      ballDiameter: scene?.ballDiameter || DEFAULT_BALL_DIAMETER,
      ballScreenSizePercentage:
        scene?.ballScreenSizePercentage || BALL_SCREEN_INITIAL_SIZE_PERCENTAGE,
      transitionElementRadius:
        scene?.transitionElementRadius || TRANSITION_ELEMENT_INITIAL_RADIUS,
      bgImage: data.bgImage || "None",
    });
    setEditScene(false);
  };

  return (
    <form
      onSubmit={handleSubmit(handleEditScene)}
      className="mb-2 flex w-full flex-col gap-3"
    >
      {/* Title */}
      <div className="flex items-center gap-3">
        <p>Title:&nbsp;</p>
        <InputText
          {...register("title", { required: "Title is required" })}
          className="rounded-lg border border-slate-900 p-2"
        />
        {errors?.title && (
          <p className="text-red-500">{errors.title.message}</p>
        )}
      </div>
      {/* State */}
      <div className="flex items-center gap-3">
        <p>State:&nbsp;</p>
        <Controller
          control={control}
          name="state"
          render={({ field: { onChange, onBlur, value } }) => (
            <Dropdown
              value={value}
              onChange={onChange}
              options={Object.values(SCENE_STATES)}
              optionValue="value"
              optionLabel="label"
              placeholder="Select Scene State"
              className="rounded-lg border border-slate-900"
            />
          )}
        />

        {errors?.state && (
          <p className="text-red-500">{errors.state.message}</p>
        )}
      </div>
      {/* Scale */}
      <div className="flex items-center gap-3">
        <p>Scale:&nbsp;</p>
        {/* X Scale */}
        <div className="flex items-center gap-3">
          <InputText
            {...register("scaleX", {
              required: "Horizontal Scale is required",
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.scaleX && (
            <p className="text-red-500">{errors.scaleX.message}</p>
          )}
        </div>
        {/* Y Scale */}
        <div className="flex items-center gap-3">
          <span>:</span>
          <InputText
            {...register("scaleY", {
              required: "Vertical Scale is required",
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.scaleY && (
            <p className="text-red-500">{errors.scaleY.message}</p>
          )}
        </div>
      </div>
      {/* Ball Diameter */}
      <div className="flex items-center gap-3">
        <p>Ball Diameter:&nbsp;</p>
        <div className="flex items-center gap-3">
          <InputText
            {...register("ballDiameter", {
              required: "Ball Diameter is required",
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.ballDiameter && (
            <p className="text-red-500">{errors.ballDiameter.message}</p>
          )}
        </div>
      </div>
      {/* Ball Screen Size Percentage */}
      <div className="flex items-center gap-3">
        <p>Ball Diameter to Screen Width Percentage:&nbsp;</p>
        <div className="flex items-center gap-3">
          <InputText
            {...register("ballScreenSizePercentage", {
              required: "Ball Screen Size Percentage is required",
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.ballScreenSizePercentage && (
            <p className="text-red-500">
              {errors.ballScreenSizePercentage.message}
            </p>
          )}
        </div>
      </div>
      {/* Transition Element Radius */}
      <div className="flex items-center gap-3">
        <p>Transition Element Radius:&nbsp;</p>
        <div className="flex items-center gap-3">
          <InputText
            {...register("transitionElementRadius", {
              required: "Transition Element Radius is required",
            })}
            className="w-20 rounded-lg border border-slate-900 p-1"
          />
          {errors?.transitionElementRadius && (
            <p className="text-red-500">
              {errors.transitionElementRadius.message}
            </p>
          )}
        </div>
      </div>
      {/* Background List */}
      <div className="flex items-center gap-3">
        <ListBox
          {...register("bgImage", {
            required: false,
          })}
          options={BACKGROUND_IMAGES}
          itemTemplate={(option) => <BgImageListTemplate option={option} />}
          className="w-full p-4"
          pt={{
            list: { className: "flex items-center gap-3" },
            item: { className: "shrink-0 w-64 rounded-lg overflow-x-auto" },
          }}
        />
      </div>
      <div className="flex gap-3 self-end">
        <Button
          label="Cancel"
          severity="danger"
          type="button"
          onClick={() => {
            reset({
              title: scene?.title,
              scaleX: scene?.scale?.x,
              scaleY: scene?.scale?.y,
              bgImage: scene?.bgImage,
            });
            setEditScene(false);
          }}
        />
        <Button severity="success" label="Confirm" />
      </div>
    </form>
  );
};

const BgImageListTemplate = ({ option }) => {
  return (
    <div className="flex w-full flex-col items-center gap-3">
      <img
        src={option.images[0]}
        alt={option.value}
        className="h-40 w-40 rounded-lg object-cover"
      />
      <p className="text-center">{option.value}</p>
    </div>
  );
};
