// Constants
import {
  ANIMATION_TYPES,
  LINE_TYPES,
  // FIRST_CONTROL_POINT_INDEX,
  // FIRST_DATA_POINT_INDEX,
  OBSTACLE_TYPES,
  TARGET_TYPES,
  // SECOND_CONTROL_POINT_INDEX,
  // SECOND_DATA_POINT_INDEX,
} from "../constants/constants";

// Convert Element x & y points system from relative position to absolute position
export const relativeToAbsolute = (
  prevElement,
  element,
  elementIndex,
  chainStartPoint,
  scale,
) => {
  const updatedTargetPoints = [];
  const updatedObstacles = [];

  const startPointX =
    elementIndex === 0
      ? chainStartPoint.x * scale.x
      : prevElement.points.endPoint.x;
  const startPointY =
    elementIndex === 0
      ? chainStartPoint.y * scale.y
      : prevElement.points.endPoint.y;

  if (element?.type !== 6) {
    element?.targets?.forEach((target) => {
      let updatedTarget;

      updatedTarget =
        target.type === TARGET_TYPES.FLAG.value
          ? validateFlagTarget(target)
          : validatePointTarget(target);

      updatedTarget = {
        ...updatedTarget,
        objectPosition: {
          x: target.objectPosition.x * scale.x + startPointX,
          y: target.objectPosition.y * scale.y + startPointY,
        },
        size: target.size * ((scale.x + scale.y) / 2),
      };
      updatedTargetPoints.push(updatedTarget);
    });

    element?.obstacles?.forEach((obstacle) => {
      let updatedObstacle;
      if (obstacle.type === OBSTACLE_TYPES.CURVE.value) {
        updatedObstacle = validateCurveObstacle(obstacle);
        updatedObstacle = {
          ...updatedObstacle,
          curvePoints: {
            startPoint: {
              x:
                startPointX +
                parseFloat(obstacle.curvePoints.startPoint.x.toFixed(4)) *
                  scale.x,
              y:
                startPointY +
                parseFloat(obstacle.curvePoints.startPoint.y.toFixed(4)) *
                  scale.y,
            },
            firstControlPoint: {
              x:
                obstacle.type === OBSTACLE_TYPES.CURVE.value
                  ? startPointX +
                    parseFloat(
                      obstacle.curvePoints.firstControlPoint.x.toFixed(4),
                    ) *
                      scale.x
                  : 0,
              y:
                obstacle.type === OBSTACLE_TYPES.CURVE.value
                  ? startPointY +
                    parseFloat(
                      obstacle.curvePoints.firstControlPoint.y.toFixed(4),
                    ) *
                      scale.y
                  : 0,
            },
            secondControlPoint: {
              x:
                obstacle.type === OBSTACLE_TYPES.CURVE.value
                  ? startPointX +
                    parseFloat(
                      obstacle.curvePoints.secondControlPoint.x.toFixed(4),
                    ) *
                      scale.x
                  : 0,
              y:
                obstacle.type === OBSTACLE_TYPES.CURVE.value
                  ? startPointY +
                    parseFloat(
                      obstacle.curvePoints.secondControlPoint.y.toFixed(4),
                    ) *
                      scale.y
                  : 0,
            },
            endPoint: {
              x:
                obstacle.type === OBSTACLE_TYPES.CURVE.value
                  ? startPointX +
                    parseFloat(obstacle.curvePoints.endPoint.x.toFixed(4)) *
                      scale.x
                  : 0,
              y:
                obstacle.type === OBSTACLE_TYPES.CURVE.value
                  ? startPointY +
                    parseFloat(obstacle.curvePoints.endPoint.y.toFixed(4)) *
                      scale.y
                  : 0,
            },
          },
          objectPosition: {
            x:
              startPointX +
              parseFloat(obstacle.objectPosition.x.toFixed(4)) * scale.x,
            y:
              startPointY +
              parseFloat(obstacle.objectPosition.y.toFixed(4)) * scale.y,
          },
          lineWidth: Number(obstacle.lineWidth),
          friction: Number(obstacle.friction),
          restitution: Number(obstacle.restitution),
        };
      } else {
        updatedObstacle = validateObjectObstacle(obstacle);
        updatedObstacle = {
          ...updatedObstacle,
          objectPosition: {
            x:
              startPointX +
              parseFloat(obstacle.objectPosition.x.toFixed(4)) * scale.x,
            y:
              startPointY +
              parseFloat(obstacle.objectPosition.y.toFixed(4)) * scale.y,
          },
          lineWidth: Number(obstacle.lineWidth),
          friction: Number(obstacle.friction),
          restitution: Number(obstacle.restitution),
          width: Number(obstacle.width) * scale.x,
          height: Number(obstacle.height) * scale.y,
          rotation: Number(obstacle.rotation),
        };
      }

      if (updatedObstacle.animationType !== ANIMATION_TYPES.NONE) {
        updatedObstacle.animation = {
          ...obstacle.animation,
          speed: obstacle.animation.speed * ((scale.x + scale.y) / 2),
          motionPoint: {
            x:
              startPointX +
              parseFloat(obstacle.animation.motionPoint.x.toFixed(4)) * scale.x,
            y:
              startPointY +
              parseFloat(obstacle.animation.motionPoint.y.toFixed(4)) * scale.y,
          },
          distance: {
            x: parseFloat(obstacle.animation.distance.x.toFixed(4)) * scale.x,
            y: parseFloat(obstacle.animation.distance.y.toFixed(4)) * scale.y,
          },
        };
      }

      updatedObstacles.push(updatedObstacle);
    });
  }

  let updatedElement;
  switch (element?.type) {
    case LINE_TYPES.FLAT_LINE.value: // FLAT LINE ELEMENT
      updatedElement = validateLineElement(element);
      updatedElement = {
        ...updatedElement,
        targets: updatedTargetPoints,
        obstacles: updatedObstacles,
        rippleHeight: element.rippleHeight * scale.y,
        points: {
          startPoint: {
            x: parseFloat(startPointX.toFixed(4)),
            y: parseFloat(startPointY.toFixed(4)),
          },
          endPoint: {
            x:
              startPointX +
              parseFloat(updatedElement.points.endPoint.x.toFixed(4)) * scale.x,
            y:
              startPointY +
              parseFloat(updatedElement.points.endPoint.y.toFixed(4)) * scale.y,
          },
        },
      };
      break;
    case LINE_TYPES.GAP.value: // GAP ELEMENT
      updatedElement = validateGapElement(element);
      updatedElement = {
        ...updatedElement,
        targets: updatedTargetPoints,
        obstacles: updatedObstacles,
        points: {
          startPoint: {
            x: parseFloat(startPointX.toFixed(4)),
            y: parseFloat(startPointY.toFixed(4)),
          },
          endPoint: {
            x:
              startPointX +
              parseFloat(updatedElement.points.endPoint.x.toFixed(4)) * scale.x,
            y:
              startPointY +
              parseFloat(updatedElement.points.endPoint.y.toFixed(4)) * scale.y,
          },
        },
      };
      break;
    case LINE_TYPES.DOWNHILL_CURVE.value: // DOWNHILL CURVE ELEMENT
    case LINE_TYPES.UPHILL_CURVE.value: // UPHILL CURVE ELEMENT
    case LINE_TYPES.FLAT_CURVE.value: // FLAT CURVE ELEMENT
      updatedElement = validateBezierElement(element);
      updatedElement = {
        ...updatedElement,
        targets: updatedTargetPoints,
        obstacles: updatedObstacles,
        points: {
          startPoint: {
            x: parseFloat(startPointX.toFixed(4)),
            y: parseFloat(startPointY.toFixed(4)),
          },
          firstControlPoint: {
            x:
              startPointX +
              parseFloat(updatedElement.points.firstControlPoint.x.toFixed(4)) *
                scale.x,
            y:
              startPointY +
              parseFloat(updatedElement.points.firstControlPoint.y.toFixed(4)) *
                scale.y,
          },
          secondControlPoint: {
            x:
              startPointX +
              parseFloat(
                updatedElement.points.secondControlPoint.x.toFixed(4),
              ) *
                scale.x,
            y:
              startPointY +
              parseFloat(
                updatedElement.points.secondControlPoint.y.toFixed(4),
              ) *
                scale.y,
          },
          endPoint: {
            x:
              startPointX +
              parseFloat(updatedElement.points.endPoint.x.toFixed(4)) * scale.x,
            y:
              startPointY +
              parseFloat(updatedElement.points.endPoint.y.toFixed(4)) * scale.y,
          },
        },
      };
      break;
    case LINE_TYPES.BUMP_CURVE.value: // BUMP CURVE ELEMENT
      updatedElement = validateBumpElement(element);
      updatedElement = {
        ...updatedElement,
        targets: updatedTargetPoints,
        obstacles: updatedObstacles,
        points: {
          startPoint: {
            x: parseFloat(startPointX.toFixed(4)),
            y: parseFloat(startPointY.toFixed(4)),
          },
          firstControlPoint: {
            x:
              startPointX +
              parseFloat(updatedElement.points.firstControlPoint.x.toFixed(4)) *
                scale.x,
            y:
              startPointY +
              parseFloat(updatedElement.points.firstControlPoint.y.toFixed(4)) *
                scale.y,
          },
          endPoint: {
            x:
              startPointX +
              parseFloat(updatedElement.points.endPoint.x.toFixed(4)) * scale.x,
            y:
              startPointY +
              parseFloat(updatedElement.points.endPoint.y.toFixed(4)) * scale.y,
          },
        },
      };
      break;
    default:
      updatedElement = validateBezierElement(element);
      updatedElement = {
        ...updatedElement,
        targets: updatedTargetPoints,
        obstacles: updatedObstacles,
        points: {
          startPoint: {
            x: parseFloat(startPointX.toFixed(4)),
            y: parseFloat(startPointY.toFixed(4)),
          },
          firstControlPoint: {
            x:
              startPointX +
              parseFloat(updatedElement.points.firstControlPoint.x.toFixed(4)) *
                scale.x,
            y:
              startPointY +
              parseFloat(updatedElement.points.firstControlPoint.y.toFixed(4)) *
                scale.y,
          },
          secondControlPoint: {
            x:
              startPointX +
              parseFloat(
                updatedElement.points.secondControlPoint.x.toFixed(4),
              ) *
                scale.x,
            y:
              startPointY +
              parseFloat(
                updatedElement.points.secondControlPoint.y.toFixed(4),
              ) *
                scale.y,
          },
          endPoint: {
            x:
              startPointX +
              parseFloat(updatedElement.points.endPoint.x.toFixed(4)) * scale.x,
            y:
              startPointY +
              parseFloat(updatedElement.points.endPoint.y.toFixed(4)) * scale.y,
          },
        },
      };
      break;
  }

  // Validate Falling Element
  if (element.isFalling) {
    const validatedFallingData = validateFallingElement(element);
    updatedElement.falling = validatedFallingData;
  }

  return updatedElement;
};

// Convert Element x & y points system from absolute position to relative position
export const absoluteToRelative = (absoluteScene) => {
  if (absoluteScene === undefined) return null;

  const relativeScene = { ...absoluteScene, chains: [] };
  absoluteScene.chains.forEach((chain) => {
    const elements = [];

    chain.elements.forEach((element) => {
      const updatedTargets = [];
      const updatedObstacles = [];
      // Discard transition elements
      if (element.type !== 6) {
        const { targets, obstacles } = element;

        // Convert target points to element relative coordinates
        targets?.forEach((target) => {
          const relativeTarget = {
            ...target,
            objectPosition: {
              x:
                (target.objectPosition.x - element.points.startPoint.x) /
                absoluteScene.scale.x,
              y:
                (target.objectPosition.y - element.points.startPoint.y) /
                absoluteScene.scale.y,
            },
            size:
              target.size /
              ((absoluteScene.scale.x + absoluteScene.scale.y) / 2),
          };
          updatedTargets.push(relativeTarget);
        });

        // Convert obstacle points to element relative coordinates
        obstacles?.forEach((obstacle) => {
          let relativeObstacle;
          if (obstacle.type === OBSTACLE_TYPES.CURVE.value) {
            if (obstacle.animationType !== ANIMATION_TYPES.NONE) {
              relativeObstacle = {
                ...obstacle,
                curvePoints: {
                  startPoint: {
                    x:
                      (obstacle.curvePoints.startPoint.x -
                        element.points.startPoint.x) /
                      absoluteScene.scale.x,
                    y:
                      (obstacle.curvePoints.startPoint.y -
                        element.points.startPoint.y) /
                      absoluteScene.scale.y,
                  },
                  firstControlPoint: {
                    x:
                      obstacle.type === OBSTACLE_TYPES.CURVE.value
                        ? (obstacle.curvePoints.firstControlPoint.x -
                            element.points.startPoint.x) /
                          absoluteScene.scale.x
                        : 0,

                    y:
                      obstacle.type === OBSTACLE_TYPES.CURVE.value
                        ? (obstacle.curvePoints.firstControlPoint.y -
                            element.points.startPoint.y) /
                          absoluteScene.scale.y
                        : 0,
                  },
                  secondControlPoint: {
                    x:
                      obstacle.type === OBSTACLE_TYPES.CURVE.value
                        ? (obstacle.curvePoints.secondControlPoint.x -
                            element.points.startPoint.x) /
                          absoluteScene.scale.x
                        : 0,
                    y:
                      obstacle.type === OBSTACLE_TYPES.CURVE.value
                        ? (obstacle.curvePoints.secondControlPoint.y -
                            element.points.startPoint.y) /
                          absoluteScene.scale.y
                        : 0,
                  },
                  endPoint: {
                    x:
                      obstacle.type === OBSTACLE_TYPES.CURVE.value
                        ? (obstacle.curvePoints.endPoint.x -
                            element.points.startPoint.x) /
                          absoluteScene.scale.x
                        : 0,
                    y:
                      obstacle.type === OBSTACLE_TYPES.CURVE.value
                        ? (obstacle.curvePoints.endPoint.y -
                            element.points.startPoint.y) /
                          absoluteScene.scale.y
                        : 0,
                  },
                },
                objectPosition: {
                  x:
                    (obstacle.objectPosition.x - element.points.startPoint.x) /
                    absoluteScene.scale.x,
                  y:
                    (obstacle.objectPosition.y - element.points.startPoint.y) /
                    absoluteScene.scale.y,
                },
                animation: {
                  ...obstacle.animation,
                  speed:
                    obstacle.animation.speed /
                    ((absoluteScene.scale.x + absoluteScene.scale.y) / 2),
                  motionPoint: {
                    x:
                      (obstacle.animation.motionPoint.x -
                        element.points.startPoint.x) /
                      absoluteScene.scale.x,
                    y:
                      (obstacle.animation.motionPoint.y -
                        element.points.startPoint.y) /
                      absoluteScene.scale.y,
                  },
                  distance: {
                    x: obstacle.animation.distance.x / absoluteScene.scale.x,
                    y: obstacle.animation.distance.y / absoluteScene.scale.y,
                  },
                },
              };
            } else {
              relativeObstacle = {
                ...obstacle,
                curvePoints: {
                  startPoint: {
                    x:
                      (obstacle.curvePoints.startPoint.x -
                        element.points.startPoint.x) /
                      absoluteScene.scale.x,
                    y:
                      (obstacle.curvePoints.startPoint.y -
                        element.points.startPoint.y) /
                      absoluteScene.scale.y,
                  },
                  firstControlPoint: {
                    x:
                      obstacle.type === OBSTACLE_TYPES.CURVE.value
                        ? (obstacle.curvePoints.firstControlPoint.x -
                            element.points.startPoint.x) /
                          absoluteScene.scale.x
                        : 0,

                    y:
                      obstacle.type === OBSTACLE_TYPES.CURVE.value
                        ? (obstacle.curvePoints.firstControlPoint.y -
                            element.points.startPoint.y) /
                          absoluteScene.scale.y
                        : 0,
                  },
                  secondControlPoint: {
                    x:
                      obstacle.type === OBSTACLE_TYPES.CURVE.value
                        ? (obstacle.curvePoints.secondControlPoint.x -
                            element.points.startPoint.x) /
                          absoluteScene.scale.x
                        : 0,
                    y:
                      obstacle.type === OBSTACLE_TYPES.CURVE.value
                        ? (obstacle.curvePoints.secondControlPoint.y -
                            element.points.startPoint.y) /
                          absoluteScene.scale.y
                        : 0,
                  },
                  endPoint: {
                    x:
                      obstacle.type === OBSTACLE_TYPES.CURVE.value
                        ? (obstacle.curvePoints.endPoint.x -
                            element.points.startPoint.x) /
                          absoluteScene.scale.x
                        : 0,
                    y:
                      obstacle.type === OBSTACLE_TYPES.CURVE.value
                        ? (obstacle.curvePoints.endPoint.y -
                            element.points.startPoint.y) /
                          absoluteScene.scale.y
                        : 0,
                  },
                },
                objectPosition: {
                  x:
                    (obstacle.objectPosition.x - element.points.startPoint.x) /
                    absoluteScene.scale.x,
                  y:
                    (obstacle.objectPosition.y - element.points.startPoint.y) /
                    absoluteScene.scale.y,
                },
              };
            }
          } else {
            if (obstacle.animationType !== ANIMATION_TYPES.NONE) {
              relativeObstacle = {
                ...obstacle,
                objectPosition: {
                  x:
                    (obstacle.objectPosition.x - element.points.startPoint.x) /
                    absoluteScene.scale.x,
                  y:
                    (obstacle.objectPosition.y - element.points.startPoint.y) /
                    absoluteScene.scale.y,
                },
                animation: {
                  ...obstacle.animation,
                  speed:
                    obstacle.animation.speed /
                    ((absoluteScene.scale.x + absoluteScene.scale.y) / 2),
                  motionPoint: {
                    x:
                      (obstacle.animation.motionPoint.x -
                        element.points.startPoint.x) /
                      absoluteScene.scale.x,
                    y:
                      (obstacle.animation.motionPoint.y -
                        element.points.startPoint.y) /
                      absoluteScene.scale.y,
                  },
                  distance: {
                    x: obstacle.animation.distance.x / absoluteScene.scale.x,
                    y: obstacle.animation.distance.y / absoluteScene.scale.y,
                  },
                },
                width: Number(obstacle.width) / absoluteScene.scale.x,
                height: Number(obstacle.height) / absoluteScene.scale.y,
              };
            } else {
              relativeObstacle = {
                ...obstacle,
                objectPosition: {
                  x:
                    (obstacle.objectPosition.x - element.points.startPoint.x) /
                    absoluteScene.scale.x,
                  y:
                    (obstacle.objectPosition.y - element.points.startPoint.y) /
                    absoluteScene.scale.y,
                },
                width: Number(obstacle.width) / absoluteScene.scale.x,
                height: Number(obstacle.height) / absoluteScene.scale.y,
              };
            }
          }

          updatedObstacles.push(relativeObstacle);
        });
        let relativeElement;
        switch (element.type) {
          case LINE_TYPES.FLAT_LINE.value: // FLAT LINE ELEMENT
            relativeElement = {
              ...element,
              targets: updatedTargets,
              obstacles: updatedObstacles,
              rippleHeight: element.rippleHeight / absoluteScene.scale.y,
              points: {
                startPoint: {
                  x: 0,
                  y: 0,
                },
                endPoint: {
                  x:
                    (element.points.endPoint.x - element.points.startPoint.x) /
                    absoluteScene.scale.x,
                  y:
                    (element.points.endPoint.y - element.points.startPoint.y) /
                    absoluteScene.scale.y,
                },
              },
            };
            break;
          case LINE_TYPES.GAP.value: // GAP ELEMENT
            relativeElement = {
              ...element,
              targets: updatedTargets,
              obstacles: updatedObstacles,
              points: {
                startPoint: {
                  x: 0,
                  y: 0,
                },
                endPoint: {
                  x:
                    (element.points.endPoint.x - element.points.startPoint.x) /
                    absoluteScene.scale.x,
                  y:
                    (element.points.endPoint.y - element.points.startPoint.y) /
                    absoluteScene.scale.y,
                },
              },
            };
            break;
          case LINE_TYPES.DOWNHILL_CURVE.value: // DOWNHILL CURVE ELEMENT
          case LINE_TYPES.UPHILL_CURVE.value: // UPHILL CURVE ELEMENT
          case LINE_TYPES.FLAT_CURVE.value: // FLAT CURVE ELEMENT
            relativeElement = {
              ...element,
              targets: updatedTargets,
              obstacles: updatedObstacles,
              points: {
                startPoint: {
                  x: 0,
                  y: 0,
                },
                firstControlPoint: {
                  x:
                    (element.points.firstControlPoint.x -
                      element.points.startPoint.x) /
                    absoluteScene.scale.x,
                  y:
                    (element.points.firstControlPoint.y -
                      element.points.startPoint.y) /
                    absoluteScene.scale.y,
                },
                secondControlPoint: {
                  x:
                    (element.points.secondControlPoint.x -
                      element.points.startPoint.x) /
                    absoluteScene.scale.x,
                  y:
                    (element.points.secondControlPoint.y -
                      element.points.startPoint.y) /
                    absoluteScene.scale.y,
                },
                endPoint: {
                  x:
                    (element.points.endPoint.x - element.points.startPoint.x) /
                    absoluteScene.scale.x,
                  y:
                    (element.points.endPoint.y - element.points.startPoint.y) /
                    absoluteScene.scale.y,
                },
              },
            };
            break;
          case LINE_TYPES.BUMP_CURVE.value: // BUMP CURVE ELEMENT
            relativeElement = {
              ...element,
              targets: updatedTargets,
              obstacles: updatedObstacles,
              points: {
                startPoint: {
                  x: 0,
                  y: 0,
                },
                firstControlPoint: {
                  x:
                    (element.points.firstControlPoint.x -
                      element.points.startPoint.x) /
                    absoluteScene.scale.x,
                  y:
                    (element.points.firstControlPoint.y -
                      element.points.startPoint.y) /
                    absoluteScene.scale.y,
                },
                endPoint: {
                  x:
                    (element.points.endPoint.x - element.points.startPoint.x) /
                    absoluteScene.scale.x,
                  y:
                    (element.points.endPoint.y - element.points.startPoint.y) /
                    absoluteScene.scale.y,
                },
              },
            };
            break;
          default:
            break;
        }

        elements.push(relativeElement);
      }
    });
    relativeScene.chains.push({ elements, startPoint: chain.startPoint });
  });

  return relativeScene;
};

const validateLineElement = (element) => {
  const validatedElement = {
    type: element.type,
    color: element.color,
    friction: Number(element.friction),
    lineWidth: Number(element.lineWidth),
    restitution: Number(element.restitution),
    targets: element.targets,
    obstacles: element.obstacles,
    points: {
      startPoint: element.points.startPoint,
      endPoint: element.points.endPoint,
    },
    rippleDensity: Number(element.rippleDensity),
    rippleHeight: Number(element.rippleHeight),
    isFalling: element.isFalling,
  };

  return validatedElement;
};

const validateGapElement = (element) => {
  const validatedElement = {
    type: element.type,
    targets: element.targets,
    obstacles: element.obstacles,
    points: {
      startPoint: element.points.startPoint,
      endPoint: element.points.endPoint,
    },
    isFalling: element.isFalling,
  };

  return validatedElement;
};
const validateBumpElement = (element) => {
  const validatedElement = {
    type: element.type,
    color: element.color,
    friction: Number(element.friction),
    lineWidth: Number(element.lineWidth),
    restitution: Number(element.restitution),
    targets: element.targets,
    obstacles: element.obstacles,
    points: {
      startPoint: element.points.startPoint,
      endPoint: element.points.endPoint,
      firstControlPoint: element.points.firstControlPoint,
    },
    isFalling: element.isFalling,
  };

  return validatedElement;
};
const validateBezierElement = (element) => {
  const validatedElement = {
    type: element.type,
    color: element.color,
    friction: Number(element.friction),
    lineWidth: Number(element.lineWidth),
    restitution: Number(element.restitution),
    targets: element.targets,
    obstacles: element.obstacles,
    points: {
      startPoint: element.points.startPoint,
      endPoint: element.points.endPoint,
      firstControlPoint: element.points.firstControlPoint,
      secondControlPoint: element.points.secondControlPoint,
    },
    isFalling: element.isFalling,
  };

  return validatedElement;
};

const validateObjectObstacle = (obstacle) => {
  return {
    type: obstacle.type,
    outline: obstacle.outline,
    lineWidth: obstacle.lineWidth,
    friction: obstacle.friction,
    restitution: obstacle.restitution,
    animationType: obstacle.animationType,
    objectPosition: obstacle.objectPosition,
    fill: obstacle.fill,
    width: obstacle.width,
    height: obstacle.height,
    rotation: obstacle.rotation,
  };
};
const validateCurveObstacle = (obstacle) => {
  return {
    type: obstacle.type,
    outline: obstacle.outline,
    lineWidth: obstacle.lineWidth,
    friction: obstacle.friction,
    restitution: obstacle.restitution,
    animationType: obstacle.animationType,
    objectPosition: obstacle.objectPosition,
    curvePoints: {
      startPoint: obstacle.curvePoints.startPoint,
      firstControlPoint: obstacle.curvePoints.firstControlPoint,
      secondControlPoint: obstacle.curvePoints.secondControlPoint,
      endPoint: obstacle.curvePoints.endPoint,
    },
  };
};

const validatePointTarget = (target) => {
  return {
    type: target.type,
    size: target.size,
    objectPosition: target.objectPosition,
    fill: target.fill,
    value: target.value,
  };
};
const validateFlagTarget = (target) => {
  return {
    type: target.type,
    size: target.size,
    objectPosition: target.objectPosition,
    fill: target.fill,
    nextScene: target.nextScene,
  };
};

const validateFallingElement = (element) => {
  const validatedFallingObject = {
    fallDelay: Number(element.falling.fallDelay),
    regenerate: element.falling.regenerate,
    regenerateDelay: Number(element.falling.regenerateDelay),
  };

  return validatedFallingObject;
};
