import { doc, getDoc, setDoc } from "firebase/firestore";

import { fireStore } from "../firesbase";

const colorDocId = "color-settings";
export const fetchCustomColorSettings = async () => {
  try {
    const docRef = doc(
      fireStore,
      process.env.REACT_APP_FIREBASE_SETTINGS_COLLECTION,
      colorDocId,
    );
    const docSnap = await getDoc(docRef);

    return docSnap.data();
  } catch (error) {
    console.error("Error fetching document data:", error);
  }
};

export const updateCustomColorSettings = async (colors) => {
  try {
    const docRef = doc(
      fireStore,
      process.env.REACT_APP_FIREBASE_SETTINGS_COLLECTION,
      colorDocId,
    );
    await setDoc(docRef, colors);
  } catch (error) {
    console.error("Error writing document:", error);
  }
};
