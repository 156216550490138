// Constants
import {
  ANIMATION_TYPES,
  DIMENSIONS_FACTOR_OF_SAFETY,
  OBSTACLE_TYPES,
} from "../constants/constants";

export const setSceneDimensions = (scene) => {
  const sceneDimension = {
    min: {
      x: scene.startPoint.x * scene.scale.x,
      y: scene.startPoint.y * scene.scale.y,
    },
    max: {
      x: scene.startPoint.x * scene.scale.x,
      y: scene.startPoint.y * scene.scale.y,
    },
  };

  scene?.chains?.forEach((chain) => {
    chain?.elements.forEach((element) => {
      // ripple height position
      if (element.type === 0) {
        // min
        sceneDimension.min.y = setMinimum(
          sceneDimension.min.y,
          element.points.startPoint.y - Math.abs(element.rippleHeight),
        );

        // max
        sceneDimension.max.y = setMaximum(
          sceneDimension.max.y,
          element.points.startPoint.y + Math.abs(element.rippleHeight),
        );
      }

      // element points position
      Object.values(element.points).forEach((point) => {
        // min
        sceneDimension.min.x = setMinimum(sceneDimension.min.x, point.x);
        sceneDimension.min.y = setMinimum(sceneDimension.min.y, point.y);

        // max
        sceneDimension.max.x = setMaximum(sceneDimension.max.x, point.x);
        sceneDimension.max.y = setMaximum(sceneDimension.max.y, point.y);
      });

      // target position
      element?.targets.forEach((target) => {
        // min
        sceneDimension.min.x = setMinimum(
          sceneDimension.min.x,
          target.objectPosition.x - target.size / 2,
        );
        sceneDimension.min.y = setMinimum(
          sceneDimension.min.y,
          target.objectPosition.y - target.size / 2,
        );
        // max
        sceneDimension.max.x = setMaximum(
          sceneDimension.max.x,
          target.objectPosition.x + target.size / 2,
        );
        sceneDimension.max.y = setMaximum(
          sceneDimension.max.y,
          target.objectPosition.y + target.size / 2,
        );
      });
      // obstacle position
      element?.obstacles.forEach((obstacle) => {
        // curve points position
        if (obstacle.type === OBSTACLE_TYPES.CURVE.value) {
          Object.values(obstacle.curvePoints).forEach((point) => {
            // min
            sceneDimension.min.x = setMinimum(sceneDimension.min.x, point.x);
            sceneDimension.min.y = setMinimum(sceneDimension.min.y, point.y);

            // max
            sceneDimension.max.x = setMaximum(sceneDimension.max.x, point.x);
            sceneDimension.max.y = setMaximum(sceneDimension.max.y, point.y);

            // animation position
            if (obstacle.animationType !== ANIMATION_TYPES.NONE) {
              // min
              sceneDimension.min.x = setMinimum(
                sceneDimension.min.x,
                obstacle.animation.motionPoint.x -
                  (point.x - obstacle.objectPosition.x),
              );
              sceneDimension.min.y = setMinimum(
                sceneDimension.min.y,
                obstacle.animation.motionPoint.y -
                  (point.y - obstacle.objectPosition.y),
              );

              // max
              sceneDimension.max.x = setMaximum(
                sceneDimension.max.x,
                obstacle.animation.motionPoint.x +
                  point.x -
                  obstacle.objectPosition.x,
              );
              sceneDimension.max.y = setMaximum(
                sceneDimension.max.y,
                obstacle.animation.motionPoint.y +
                  point.y -
                  obstacle.objectPosition.y,
              );
            }
          });
        } else {
          // object position
          // min
          sceneDimension.min.x = setMinimum(
            sceneDimension.min.x,
            obstacle.objectPosition.x - obstacle.width / 2,
          );
          sceneDimension.min.y = setMinimum(
            sceneDimension.min.y,
            obstacle.objectPosition.y - obstacle.height / 2,
          );

          // max
          sceneDimension.max.x = setMaximum(
            sceneDimension.max.x,
            obstacle.objectPosition.x + obstacle.width / 2,
          );
          sceneDimension.max.y = setMaximum(
            sceneDimension.max.y,
            obstacle.objectPosition.y + obstacle.height / 2,
          );

          // animation position
          if (obstacle.animationType !== ANIMATION_TYPES.NONE) {
            // min
            sceneDimension.min.x = setMinimum(
              sceneDimension.min.x,
              obstacle.animation.motionPoint.x - obstacle.width / 2,
            );
            sceneDimension.min.y = setMinimum(
              sceneDimension.min.y,
              obstacle.animation.motionPoint.y - obstacle.height / 2,
            );

            // max
            sceneDimension.max.x = setMaximum(
              sceneDimension.max.x,
              obstacle.animation.motionPoint.x + obstacle.width / 2,
            );
            sceneDimension.max.y = setMaximum(
              sceneDimension.max.y,
              obstacle.animation.motionPoint.y + obstacle.height / 2,
            );
          }
        }
      });
    });
  });
  return finalizeDimensions(sceneDimension, scene.scale);
};

const finalizeDimensions = (sceneDimension, scale) => {
  for (const extremum in sceneDimension) {
    sceneDimension[extremum] = {
      x:
        Math.floor(sceneDimension[extremum].x * DIMENSIONS_FACTOR_OF_SAFETY) *
        scale.x,
      y:
        Math.floor(sceneDimension[extremum].y * DIMENSIONS_FACTOR_OF_SAFETY) *
        scale.y,
    };
  }
  return sceneDimension;
};

const setMinimum = (baseValue, newValue) => {
  return baseValue < newValue ? baseValue : newValue;
};

const setMaximum = (baseValue, newValue) => {
  return baseValue > newValue ? baseValue : newValue;
};
