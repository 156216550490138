import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useForm } from "react-hook-form";

import { auth, handleFiresbaseSignInErrors } from "../firesbase";

// Store
import { useAdminStore, useTokenStore } from "../store/store";

// Utilities
import { getUserDataFromDB } from "../services/user";

// Prime React Components
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";

export const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { setToken } = useTokenStore();
  const { setIsAdmin } = useAdminStore();
  const toast = useRef();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    setIsLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = userCredential.user;
      const accessToken = await user.getIdToken();
      const userData = await getUserDataFromDB(user.uid);
      if (userData.admin) {
        setToken(accessToken);
        setIsAdmin(true);
        navigate("/dashboard", { replace: true });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Only admins can login to this site",
          life: 3000,
        });
      }
    } catch (error) {
      const errorMessage = handleFiresbaseSignInErrors(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        life: 3000,
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="w-full min-h-[100vh] flex items-center justify-center">
      <Toast ref={toast} />
      <div className="relative bg-[#f7f7f7] p-5 rounded-lg">
        <div className="absolute top-0 right-0 w-full h-full bg-wave bg-[#0996d1] rounded-lg"></div>
        <p className="tracking-widest relative font-arista text-center p-4 pt-0 z-10 text-white text-5xl">
          kGOAl
        </p>
        <p className="tracking-widest relative font-arista text-center -mt-4 z-10 text-white text-5xl">
          BOOST
        </p>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="relative flex flex-col gap-4 z-10 w-96"
        >
          <div className="flex flex-col">
            <label className="mb-2 font-bold" htmlFor="email">
              Email
            </label>
            <InputText
              name="email"
              className="w-full p-2"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address",
                },
              })}
            ></InputText>
            {errors.email && (
              <p className="text-red-500 font-bold mt-1">
                {errors.email.message}
              </p>
            )}
          </div>
          <div className="flex flex-col">
            <label className="mb-2 font-bold" htmlFor="password">
              Password
            </label>
            <InputText
              name="password"
              className="w-full p-2"
              {...register("password", {
                required: "Password is required",
              })}
              type="password"
            ></InputText>
            {errors.password && (
              <p className="text-red-500 font-bold mt-1">
                {errors.password.message}
              </p>
            )}
          </div>
          <div className="text-end mt-4">
            {isLoading ? (
              <ProgressSpinner
                pt={{
                  circle: {
                    style: {
                      stroke: "#fff",
                      strokeWidth: 3,
                      animation: "none",
                    },
                  },
                }}
                style={{ width: "30px", height: "30px" }}
                strokeWidth="4"
              />
            ) : (
              <Button
                className="bg-white py-2 px-6 text-black hover:bg-blue-200"
                label="Login"
              />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
