import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";

import { SCENE_STATES } from "../constants/constants";
import PulsingCircle from "./PulsingCircle";

const ScenesFlagsReport = ({ scenes }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const getPublishedScenes = () => {
    return scenes?.filter((scene) => scene.data.state === "published");
  };

  const getSceneById = (id) => {
    return scenes?.find((scene) => scene.id === id);
  };

  const getSceneFlags = (scene) => {
    return scene.data.chains.flatMap((chain) =>
      chain.elements.flatMap((element) =>
        element.targets.filter((target) => target.type === "flag"),
      ),
    );
  };

  useEffect(() => {
    const publishedScenes = getPublishedScenes();
    const result = [];
    publishedScenes.forEach((scene) => {
      const flags = [];
      const sceneFlags = getSceneFlags(scene);
      sceneFlags.forEach((flag) => {
        const flagDestinationScene = getSceneById(flag.nextScene);
        flags.push({
          id: flag.nextScene,
          title: flagDestinationScene?.data.title,
          status: flagDestinationScene?.data.state,
        });
      });
      result.push({ id: scene.id, title: scene.data.title, flags: flags });
    });

    setData(result);
  }, []);

  const StatusTemplate = ({ status }) => {
    if (!status) {
      return (
        <div className="flex items-center justify-center gap-4">
          <Tag rounded severity="danger" className="px-2">
            Deleted
          </Tag>
        </div>
      );
    }
    const state = Object.keys(SCENE_STATES).find(
      (key) => SCENE_STATES[key].value === status,
    );

    return (
      <div className="flex items-center justify-center gap-4">
        {state ? (
          <Tag className="px-2" rounded severity={SCENE_STATES[state].severity}>
            {SCENE_STATES[state].label}
          </Tag>
        ) : null}
      </div>
    );
  };

  const AccordionHeader = ({ scene }) => {
    const hasDeletedDestination = scene?.flags?.some((flag) => !flag.title);
    return (
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <p>{scene.title}</p>
          {hasDeletedDestination && <PulsingCircle />}
        </div>
        <Button
          rounded
          tooltip="Edit Scene"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-pencil"
          onClick={() => {
            navigate(`/scenes/${scene.id}`);
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <Accordion>
        {data.map((scene, index) => {
          return (
            <AccordionTab
              header={<AccordionHeader scene={scene} />}
              key={"acc-tab-" + index}
            >
              {scene.flags.length ? (
                <div className="flex flex-col gap-2">
                  <p className="italic text-gray-500">
                    Flags used in this scene
                  </p>
                  <div className="flex flex-col gap-4 rounded-lg border border-slate-100 p-5">
                    {scene.flags.map((flag, _index) => {
                      return (
                        <div
                          key={"flag-" + _index}
                          className="grid grid-cols-12 gap-4 px-4"
                        >
                          <p className="col-span-4">
                            {flag.title ? flag.title : flag.id}
                          </p>
                          <StatusTemplate status={flag?.status} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <p className="italic text-gray-500">No flags in this scene.</p>
              )}
            </AccordionTab>
          );
        })}
      </Accordion>
    </div>
  );
};

export default ScenesFlagsReport;
