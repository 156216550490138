// Utilities
import { imageType } from "./imageType";
import { getDraggedItem, setDraggedItem } from "./draggedItem";
import { openObjectSettings } from "./openObjectSettings";

// Constants
import { OBJECT_TYPES, TARGET_TYPES } from "../constants/constants";
import { pointOptions } from "./pointUtils";

export const createTarget = (
  board,
  element,
  target,
  focusedObject,
  index,
  edit = false,
  setElement,
  setFocusedObject,
) => {
  const width = target.size;
  const height = target.size;
  let image = board.create(
    "image",
    [
      imageType(target),
      [
        target.objectPosition.x - width / 2,
        target.objectPosition.y - height / 2,
      ],
      [width, height],
    ],
    {
      fixed: !edit,
      ...pointOptions,
    },
  );
  if (edit) {
    openObjectSettings(
      image,
      index,
      OBJECT_TYPES.TARGET,
      edit,
      false,
      focusedObject,
      setFocusedObject,
    );
    setDraggedItem(image);
    image.on("up", () => {
      if (getDraggedItem() !== image.id) return;
      const updatedPoints = [...element.targets];
      updatedPoints[index] = {
        ...updatedPoints[index],
        objectPosition: { x: image.X() + width / 2, y: image.Y() + height / 2 },
      };
      setElement({ ...element, targets: updatedPoints });
    });
  }
};
