import React from "react";
import { Navigate } from "react-router-dom";

// Store
import { useAdminStore, useTokenStore } from "../store/store";

// Pages
import { Login } from "../pages/Login";

export const LoginGuard = () => {
  const { isAdmin } = useAdminStore();
  const { token } = useTokenStore();

  if (isAdmin || token) {
    return <Navigate to="/dashboard" replace />;
  }
  return <Login />;
};
