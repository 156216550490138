// Utilities
import { relativeToAbsolute } from "./dataConverter";
import { drawTransitionWithTangents } from "./drawTransitionWithTangents";
import { getElementAngle } from "./getElementAngle";
import { setSceneDimensions } from "./setSceneDimensions";

export const buildScene = (relativeScene) => {
  if (relativeScene === null) return null;

  const absoluteScene = {
    ...relativeScene,
    chains: [],
  };

  relativeScene.chains.forEach((chain) => {
    const elements = [];
    chain.elements.forEach((element, elementIndex) => {
      let firstElementAngle;
      let secondElementAngle;

      if (elementIndex !== 0) {
        firstElementAngle = getElementAngle(
          chain.elements[elementIndex - 1],
          chain.elements[elementIndex - 1].points.secondControlPoint,
          chain.elements[elementIndex - 1].points.endPoint,
        );
        secondElementAngle = getElementAngle(
          element,
          element.points.startPoint,
          element.points.firstControlPoint,
        );
      }

      const noTransition =
        elementIndex === 0 ||
        element.type === 4 ||
        chain.elements[elementIndex - 1].type === 4 ||
        element.type === 3 ||
        chain.elements[elementIndex - 1].type === 3 ||
        firstElementAngle === secondElementAngle;

      if (noTransition) {
        // Convert element to absolute coordinates
        const absoluteElement = relativeToAbsolute(
          elements[elements.length - 1],
          element,
          elementIndex,
          chain.startPoint,
          relativeScene.scale,
        );
        elements.push(absoluteElement);
      } else {
        // Build transition element
        const transitionElement = drawTransitionWithTangents(
          chain.elements[elementIndex - 1],
          element,
          relativeScene.transitionElementRadius,
        );

        // Convert transition to absolute coordinates
        const absoluteTransitionElement = relativeToAbsolute(
          elements[elements.length - 1],
          transitionElement,
          elementIndex,
          chain.startPoint,
          relativeScene.scale,
        );

        // Convert element to absolute coordinates
        const absoluteElement = relativeToAbsolute(
          absoluteTransitionElement,
          element,
          elementIndex,
          chain.startPoint,
          relativeScene.scale,
        );
        elements.push(absoluteTransitionElement);
        elements.push(absoluteElement);
      }
    });
    absoluteScene.chains.push({ elements, startPoint: chain.startPoint });
  });

  absoluteScene.startPoint = {
    x: absoluteScene.startPoint.x,
    y: absoluteScene.startPoint.y,
  };
  absoluteScene.sceneDimensions = setSceneDimensions(absoluteScene);
  return absoluteScene;
};
