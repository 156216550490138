// Constants
import { DEFAULT_BALL_DIAMETER } from "../constants/constants";
import { pointOptions } from "./pointUtils";

export const drawStartPoint = (
  board,
  absScene,
  image,
  editStartingPoint,
  scene,
  setScene = () => {},
) => {
  const factoredSize = absScene?.ballDiameter || DEFAULT_BALL_DIAMETER;

  let target = board.create(
    "image",
    [
      image,
      [
        absScene?.startPoint?.x - factoredSize / 2,
        absScene?.startPoint?.y - factoredSize / 2,
      ],
      [factoredSize, factoredSize],
    ],
    {
      fixed: !editStartingPoint,
      ...pointOptions,
    },
  );

  if (editStartingPoint) {
    target.on("up", () => {
      setScene({
        ...scene,
        startPoint: {
          x: target.X() + factoredSize / 2,
          y: target.Y() + factoredSize / 2,
        },
      });
    });
  }
};
