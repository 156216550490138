import { pointOptions } from "./pointUtils";

export const rotateAroundCenterPoint = (board, point, rotation) => {
  board.suspendUpdate();

  let tOff = board.create(
    "transform",
    [
      function () {
        return -point.X() - point.W() * 0.5;
      },
      function () {
        return -point.Y() - point.H() * 0.5;
      },
    ],
    { type: "translate", ...pointOptions },
  );
  let tOffInverse = board.create(
    "transform",
    [
      function () {
        return point.X() + point.W() * 0.5;
      },
      function () {
        return point.Y() + point.H() * 0.5;
      },
    ],
    { type: "translate", ...pointOptions },
  );
  let tRot = board.create("transform", [(rotation * Math.PI) / 180], {
    type: "rotate",
    ...pointOptions,
  });

  tOff.bindTo(point);
  tRot.bindTo(point);
  tOffInverse.bindTo(point);
  board.unsuspendUpdate();
};
