// Constants
import { BACKGROUND_IMAGES } from "../constants/constants";

export const drawBackground = (board, bgImg, dim, scale) => {
  const imgUrlList = BACKGROUND_IMAGES.find(
    (img) => img.value === bgImg,
  ).images;
  const totalHorizontalDistance = Math.abs(dim.maxX) + Math.abs(dim.minX);
  const totalVerticalDistance =
    Math.abs(dim.minY) <= 100
      ? (Math.abs(dim.maxY) + Math.abs(dim.minY)) * scale.y
      : Math.abs(dim.maxY) + Math.abs(dim.minY);
  const imgHorizontalDistance = 1.3333333333333333 * totalVerticalDistance;
  const loopCount = Math.ceil(totalHorizontalDistance / imgHorizontalDistance);
  let i = 0;

  for (let j = 0; j < loopCount; j++) {
    if (i >= imgUrlList.length) i = 0;
    const minY = Math.abs(dim.minY) <= 100 ? dim.minY * scale.y : dim.minY;
    const bg = board.create(
      "image",
      [
        imgUrlList[i],
        [dim.minX + imgHorizontalDistance * j, minY],
        [imgHorizontalDistance, totalVerticalDistance],
      ],
      { fixed: true, highlight: false, opacity: 0.2 },
    );
    i++;
  }
};
