import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useQuery } from "@tanstack/react-query";

import Settings from "../pages/Settings";
import { useTokenData } from "../hooks/useTokenData";
import { fetchCustomColorSettings } from "../services/marbleSettings";
import {
  useAdminStore,
  useColorPaletteStore,
  useTokenStore,
} from "../store/store";

export const NavBar = () => {
  const menuRef = useRef();
  const { email } = useTokenData();
  const { setToken } = useTokenStore();
  const { setIsAdmin } = useAdminStore();
  const { setColorPalette } = useColorPaletteStore();
  const [settingsVisible, setSettingsVisible] = useState(false);

  const navigate = useNavigate();

  const colorPaletteQuery = useQuery({
    queryKey: ["customColors"],
    queryFn: fetchCustomColorSettings,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!colorPaletteQuery.data) return;
    setColorPalette(colorPaletteQuery.data.colorPalette);
  }, [colorPaletteQuery.data]);

  const dismissDialog = () => {
    setSettingsVisible(false);
  };

  const menuItems = [
    {
      label: "Options",
      items: [
        {
          label: "Settings",
          icon: "pi pi-cog",
          command: () => {
            setSettingsVisible(true);
          },
        },
        {
          label: "Logout",
          icon: "pi pi-sign-out",
          command: () => {
            setToken(null);
            setIsAdmin(null);
          },
        },
      ],
    },
  ];
  return (
    <div className="flex items-center justify-between bg-gradient-to-r from-blue-400 to-white p-5 px-12">
      <Menu ref={menuRef} popup model={menuItems} />
      <Dialog
        header="Settings"
        visible={settingsVisible}
        blockScroll
        draggable={false}
        resizable={false}
        onHide={() => setSettingsVisible(false)}
        className="w-9/12"
      >
        <div className="flex flex-col gap-6">
          <Settings dismissDialog={dismissDialog} />
        </div>
      </Dialog>
      <p
        onClick={() => {
          navigate("/dashboard", { replace: true });
        }}
        className="cursor-pointer font-arista text-5xl tracking-widest text-white transition-all duration-700 hover:text-[#e4e4e7]"
      >
        kGOAL
      </p>
      <div
        onClick={(e) => {
          menuRef.current.toggle(e);
        }}
        className="flex cursor-pointer items-center gap-2 font-semibold text-blue-500"
      >
        <i className="pi pi-user rounded-full border border-slate-500 p-2 text-slate-500"></i>
        <span>{email}</span>
        <i className="pi pi-angle-down text-slate-500"></i>
      </div>
    </div>
  );
};
