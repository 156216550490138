import React, { memo } from "react";

// Custom Hooks
import { useElementBoard } from "../hooks/useBoard";

// Constants
import { INITIAL_BOARD_DIMENSION } from "../constants/constants";

export const PreviewElement = memo(function PreviewElement({
  element,
  elementIndex,
  chainIndex,
  scenesOptions,
  navigate = false,
  edit = false,
  setElement = () => {},
}) {
  let initialDim = { ...INITIAL_BOARD_DIMENSION };

  if (!edit) initialDim.x = Math.max(element.points.endPoint.x, initialDim.x);
  useElementBoard(
    `canvas_${chainIndex}_${elementIndex}_${edit}`,
    element,
    initialDim,
    scenesOptions,
    navigate,
    edit,
    setElement,
  );

  return (
    <div
      id={`canvas_${chainIndex}_${elementIndex}_${edit}`}
      className="jxgbox flex aspect-square w-full items-center justify-center rounded-lg border border-slate-900"
    ></div>
  );
});
