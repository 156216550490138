let _dragged;

export const getDraggedItem = () => {
  return _dragged;
};

export const setDraggedItem = (item) => {
  item.on("drag", () => {
    _dragged = item.id;
  });
};
