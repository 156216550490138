import { doc, getDoc } from "firebase/firestore";

import { fireStore } from "../firesbase";

export const getUserDataFromDB = async (userId) => {
  try {
    const userDocRef = doc(fireStore, "users", userId);
    const data = await getDoc(userDocRef);
    if (data.exists()) {
      return data.data();
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
