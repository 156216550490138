import { JSXGraph } from "jsxgraph";

export const initializeBoard = (htmlElementId, dim, navigate) => {
  return JSXGraph.initBoard(htmlElementId, {
    boundingbox: [dim.minX, dim.maxY, dim.maxX, dim.minY],
    keepAspectRatio: true,
    axis: true,
    showCopyright: false,
    showNavigation: navigate,
    showZoom: navigate,
    zoom: {
      enabled: navigate,
      factorX: 1.25,
      factorY: 1.25,
      wheel: true,
      needShift: true,
      min: 0.001,
      max: 1000.0,
    },
    pan: { enabled: navigate, needTwoFingers: false, needShift: true },
    drag: { enabled: navigate },
    resize: { enabled: true, throttle: 200 },
    navbar: {
      position: "absolute",
      zIndex: "100",
      right: "5px",
      bottom: "5px",
    },
  });
};
