import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

// Guards
import { AdminAuthGuard } from "./route-guards/AdminAuthGuard";
import { LoginGuard } from "./route-guards/LoginGuard";
import { WithNav } from "./route-guards/WithNav";

// Pages
import { Login } from "./pages/Login";
import { Dashboard } from "./pages/Dashboard";
import { Scenes } from "./pages/Scenes";
import { Scene } from "./pages/Scene";

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route element={<LoginGuard />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route element={<WithNav />}>
          <Route element={<AdminAuthGuard />}>
            <Route path="*" element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/scenes" element={<Scenes />} />
            <Route path="/scenes/:sceneId" element={<Scene />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};
