import JXG from "jsxgraph";

// Constants
import {
  ANIMATION_TYPES,
  OBJECT_TYPES,
  OBSTACLE_TYPES,
} from "../constants/constants";

// Utilities
import { imageType } from "./imageType";
import { rotateAroundCenterPoint } from "./rotateAroundCenterPoint";
import { getDraggedItem, setDraggedItem } from "./draggedItem";
import { setRotationCenterPoint } from "./setRotationCenterPoint";
import { setTranslateToPoint } from "./setTranslatetoPoint";
import { openObjectSettings } from "./openObjectSettings";
import { calculateMidPoint } from "./calculateMidPoint";
import { pointOptions } from "./pointUtils";

export const createObstacle = (
  board,
  element,
  obstacle,
  focusedObject,
  index,
  edit = false,
  setElement = () => {},
  setFocusedObject,
  animate,
) => {
  let jsxObstacle;

  // Create Obstacle
  if (obstacle.type === OBSTACLE_TYPES.CURVE.value) {
    jsxObstacle = drawBezierCurve(
      board,
      element,
      obstacle,
      focusedObject,
      index,
      edit,
      animate,
      setElement,
      setFocusedObject,
    );
  } else {
    jsxObstacle = drawImage(
      board,
      element,
      obstacle,
      focusedObject,
      index,
      edit,
      animate,
      setElement,
      setFocusedObject,
    );
  }

  // Set transition Point
  if (edit && focusedObject?.index === index) {
    // Set Rotation Center Point
    if (obstacle.animationType === ANIMATION_TYPES.ROTATION) {
      setRotationCenterPoint(board, obstacle, element, setElement, index);
    }
    // Set Destination Point
    if (obstacle.animationType === ANIMATION_TYPES.SINUSOIDAL) {
      setTranslateToPoint(board, obstacle, element, setElement, index);
    }
  }

  // Preview Translation
  if (
    obstacle.animationType === ANIMATION_TYPES.SINUSOIDAL &&
    Number(obstacle.animation.speed) != 0 &&
    focusedObject?.animate &&
    focusedObject.index === index &&
    edit
  ) {
    let time;
    const LOOP_MULTIPLIER = 2;
    const NUMBER_OF_REPETITIONS = 2;

    if (animate.translate) clearTimeout(animate.translate);

    if (obstacle.type === OBSTACLE_TYPES.CURVE.value) {
      // const revolution =
      //   Math.sqrt(
      //     (obstacle.animation.motionPoint.x - obstacle.objectPosition.x) ** 2 +
      //       (obstacle.animation.motionPoint.y - obstacle.objectPosition.y) ** 2,
      //   ) * LOOP_MULTIPLIER;

      time = (1 / obstacle.animation.speed) * 1000;
      jsxObstacle.forEach((object) => {
        object.hide();
        object.visit(
          [
            obstacle.animation.motionPoint.x -
              obstacle.objectPosition.x +
              object.X(),
            obstacle.animation.motionPoint.y -
              obstacle.objectPosition.y +
              object.Y(),
          ],
          time,
          {
            repeat: NUMBER_OF_REPETITIONS,
            effect: "<>",
          },
        );
      });
    } else {
      // const revolution =
      //   Math.sqrt(
      //     (obstacle.animation.motionPoint.x -
      //       (obstacle.objectPosition.x + obstacle.width / 2)) **
      //       2 +
      //       (obstacle.animation.motionPoint.y -
      //         (obstacle.objectPosition.y + obstacle.height / 2)) **
      //         2,
      //   ) * LOOP_MULTIPLIER;
      time = (1 / obstacle.animation.speed) * 1000;

      jsxObstacle.forEach((object) =>
        object.visit(
          [
            obstacle.animation.motionPoint.x - obstacle.width / 2,
            obstacle.animation.motionPoint.y - obstacle.height / 2,
          ],
          time,
          {
            repeat: NUMBER_OF_REPETITIONS,
            effect: "<>",
          },
        ),
      );
    }

    animate.translate = setTimeout(() => {
      setFocusedObject({ ...focusedObject, animate: false });
      clearTimeout(animate.translate);
    }, time * NUMBER_OF_REPETITIONS);
  }

  // Preview Rotation
  if (
    obstacle.animationType === ANIMATION_TYPES.ROTATION &&
    Number(obstacle.animation.speed) != 0 &&
    focusedObject?.animate &&
    focusedObject.index === index &&
    edit
  ) {
    // const NUMBER_OF_REPETITIONS = 1;
    if (animate.rotate) cancelAnimationFrame(animate.rotate);
    if (obstacle.type === OBSTACLE_TYPES.CURVE.value) {
      jsxObstacle.forEach((object) => object.hide());
    }

    const rotationArray = [];
    let i = 0;

    let time = Date.now();

    const tick = () => {
      if (rotationArray.length) {
        if (board.renderer) {
          board.removeObject(rotationArray[0]);
        }
        rotationArray.pop();
      }

      let currentTime = Date.now();
      let deltaTime = currentTime - time;
      time = currentTime;
      // create rotation
      const rotate = board.create(
        "transform",
        [
          (Math.PI * obstacle.animation.speed * deltaTime) / (1000 * 180),
          [obstacle.animation.motionPoint.x, obstacle.animation.motionPoint.y],
        ],
        {
          type: "rotate",
          ...pointOptions,
        },
      );

      // rotate
      rotate.bindTo(jsxObstacle);
      rotationArray.push(rotate);

      if (i < 6) {
        i += deltaTime / 1000;
        animate.rotate = requestAnimationFrame(tick);
      } else {
        setFocusedObject({ ...focusedObject, animate: false });
        cancelAnimationFrame(animate.rotate);
      }
    };
    tick();
  }
};

const drawImage = (
  board,
  element,
  obstacle,
  focusedObject,
  index,
  edit,
  animate,
  setElement,
  setFocusedObject,
) => {
  const width = obstacle.width;
  const height = obstacle.height;

  let image = board.create(
    "image",
    [
      imageType(obstacle),
      [
        obstacle.objectPosition.x - width / 2,
        obstacle.objectPosition.y - height / 2,
      ],
      [width || 12, height || 12],
    ],
    {
      fixed: !edit,
      ...pointOptions,
    },
  );

  rotateAroundCenterPoint(board, image, obstacle.rotation);
  if (edit) {
    openObjectSettings(
      image,
      index,
      OBJECT_TYPES.OBSTACLE,
      edit,
      animate,
      focusedObject,
      setFocusedObject,
    );

    setDraggedItem(image);
    image.on("up", () => {
      if (getDraggedItem() !== image.id) return;
      const updatedPoints = [...element.obstacles];

      updatedPoints[index] = {
        ...updatedPoints[index],
        objectPosition: { x: image.X() + width / 2, y: image.Y() + height / 2 },
      };
      if (updatedPoints[index].animationType !== ANIMATION_TYPES.NONE) {
        updatedPoints[index] = {
          ...updatedPoints[index],
          animation: {
            ...updatedPoints[index].animation,
            distance: {
              x:
                updatedPoints[index].animation.motionPoint.x -
                (image.X() + width / 2),
              y:
                updatedPoints[index].animation.motionPoint.y -
                (image.Y() + height / 2),
            },
          },
        };
      }
      setElement({ ...element, obstacles: updatedPoints });
    });
  }

  return [image];
};

const drawBezierCurve = (
  board,
  element,
  obstacle,
  focusedObject,
  index,
  edit = false,
  animate,
  setElement,
  setFocusedObject,
) => {
  board.suspendUpdate();
  const points = [];
  // Curve Data Points
  const firstDataPointOptions = {
    name: 1,
    size: 4,
    fixed: !edit,
    visible: edit,
    ...pointOptions,
  };
  const secondDataPointOptions = {
    name: 4,
    size: 4,
    fixed: !edit,
    visible: edit,
    ...pointOptions,
  };
  const firstDataPoint = board.create(
    "point",
    [obstacle.curvePoints.startPoint.x, obstacle.curvePoints.startPoint.y],
    firstDataPointOptions,
  );
  const secondDataPoint = board.create(
    "point",
    [obstacle.curvePoints.endPoint.x, obstacle.curvePoints.endPoint.y],
    secondDataPointOptions,
  );
  // Curve Control Points
  const firstControlPointOptions = {
    name: 2,
    size: 4,
    fillColor: "red",
    strokeColor: "red",
    fixed: !edit,
    visible: edit,
    ...pointOptions,
  };
  const secondControlPointOptions = {
    name: 3,
    size: 4,
    fillColor: "red",
    strokeColor: "red",
    fixed: !edit,
    visible: edit,
    ...pointOptions,
  };
  const firstControlPoint = board.create(
    "point",
    [
      obstacle.curvePoints.firstControlPoint.x,
      obstacle.curvePoints.firstControlPoint.y,
    ],
    firstControlPointOptions,
  );
  const secondControlPoint = board.create(
    "point",
    [
      obstacle.curvePoints.secondControlPoint.x,
      obstacle.curvePoints.secondControlPoint.y,
    ],
    secondControlPointOptions,
  );

  points.push(
    firstDataPoint,
    firstControlPoint,
    secondControlPoint,
    secondDataPoint,
  );

  const midPointOptions = {
    name: "Drag Curve",
    label: { color: "red" },
    size: 4,
    fillColor: "green",
    strokeColor: "green",
    fixed: !edit,
    visible: edit,
    ...pointOptions,
  };
  const midPoint = board.create(
    "point",
    [obstacle.objectPosition.x, obstacle.objectPosition.y],
    midPointOptions,
  );

  // const translate = board.create(
  //   "transform",
  //   [
  //     function () {
  //       return midPoint.X();
  //     },
  //     function () {
  //       return midPoint.Y();
  //     },
  //   ],
  //   { type: "translate" },
  // );
  // midPoint.bindTo(points);

  // Curve
  const curveOptions = {
    fixed: true,
    strokeColor: obstacle.outline || "black",
    strokeWidth: obstacle.lineWidth || 4,
  };
  let curve = board.create(
    "curve",
    JXG.Math.Numerics.bezier(points),
    curveOptions,
  );

  // If element is editable, update element data
  if (edit) {
    openObjectSettings(
      curve,
      index,
      OBJECT_TYPES.OBSTACLE,
      edit,
      animate,
      focusedObject,
      setFocusedObject,
    );

    setDraggedItem(midPoint);
    midPoint.on("drag", () => {
      if (getDraggedItem() !== midPoint.id) return;
      midPoint.moveTo([midPoint.X(), midPoint.Y()]);
      firstDataPoint.moveTo([
        midPoint.X() +
          (obstacle.curvePoints.startPoint.x - obstacle.objectPosition.x),
        midPoint.Y() +
          (obstacle.curvePoints.startPoint.y - obstacle.objectPosition.y),
      ]);
      secondDataPoint.moveTo([
        midPoint.X() +
          (obstacle.curvePoints.endPoint.x - obstacle.objectPosition.x),
        midPoint.Y() +
          (obstacle.curvePoints.endPoint.y - obstacle.objectPosition.y),
      ]);
      firstControlPoint.moveTo([
        midPoint.X() +
          (obstacle.curvePoints.firstControlPoint.x -
            obstacle.objectPosition.x),
        midPoint.Y() +
          (obstacle.curvePoints.firstControlPoint.y -
            obstacle.objectPosition.y),
      ]);
      secondControlPoint.moveTo([
        midPoint.X() +
          (obstacle.curvePoints.secondControlPoint.x -
            obstacle.objectPosition.x),
        midPoint.Y() +
          (obstacle.curvePoints.secondControlPoint.y -
            obstacle.objectPosition.y),
      ]);

      // Delete old curve and create a new curve
      board.removeObject(curve);

      curve = board.create(
        "curve",
        JXG.Math.Numerics.bezier(points),
        curveOptions,
      );
    });

    // Update object position x & y
    setDraggedItem(midPoint);
    midPoint.on("up", (e) => {
      if (getDraggedItem() !== midPoint.id) return;

      const updatedPoints = [...element.obstacles];

      const updatedCurvePoints = {
        startPoint: {
          x:
            midPoint.X() +
            (obstacle.curvePoints.startPoint.x - obstacle.objectPosition.x),
          y:
            midPoint.Y() +
            (obstacle.curvePoints.startPoint.y - obstacle.objectPosition.y),
        },
        firstControlPoint: {
          x:
            midPoint.X() +
            (obstacle.curvePoints.firstControlPoint.x -
              obstacle.objectPosition.x),
          y:
            midPoint.Y() +
            (obstacle.curvePoints.firstControlPoint.y -
              obstacle.objectPosition.y),
        },
        secondControlPoint: {
          x:
            midPoint.X() +
            (obstacle.curvePoints.secondControlPoint.x -
              obstacle.objectPosition.x),
          y:
            midPoint.Y() +
            (obstacle.curvePoints.secondControlPoint.y -
              obstacle.objectPosition.y),
        },
        endPoint: {
          x:
            midPoint.X() +
            (obstacle.curvePoints.endPoint.x - obstacle.objectPosition.x),
          y:
            midPoint.Y() +
            (obstacle.curvePoints.endPoint.y - obstacle.objectPosition.y),
        },
      };

      updatedPoints[index] = {
        ...updatedPoints[index],
        curvePoints: updatedCurvePoints,
        objectPosition: { x: midPoint.X(), y: midPoint.Y() },
      };

      setElement({ ...element, obstacles: updatedPoints });
    });
    // Update first data point x & y
    setDraggedItem(firstDataPoint);
    firstDataPoint.on("up", (e) => {
      if (getDraggedItem() !== firstDataPoint.id) return;
      const updatedPoints = [...element.obstacles];

      const updatedCurvePoints = {
        ...obstacle.curvePoints,
        startPoint: { x: firstDataPoint.X(), y: firstDataPoint.Y() },
      };

      updatedPoints[index] = {
        ...updatedPoints[index],
        curvePoints: updatedCurvePoints,
        objectPosition: calculateMidPoint(updatedCurvePoints),
      };

      if (updatedPoints[index].animationType !== ANIMATION_TYPES.NONE) {
        updatedPoints[index] = {
          ...updatedPoints[index],
          animation: {
            ...updatedPoints[index].animation,
            distance: {
              x:
                updatedPoints[index].animation.motionPoint.x -
                calculateMidPoint(updatedCurvePoints).x,
              y:
                updatedPoints[index].animation.motionPoint.y -
                calculateMidPoint(updatedCurvePoints).y,
            },
          },
        };
      }

      setElement({ ...element, obstacles: updatedPoints });
    });
    // Update second data point x & y
    setDraggedItem(secondDataPoint);
    secondDataPoint.on("up", (e) => {
      if (getDraggedItem() !== secondDataPoint.id) return;

      const updatedPoints = [...element.obstacles];

      const updatedCurvePoints = {
        ...obstacle.curvePoints,
        endPoint: { x: secondDataPoint.X(), y: secondDataPoint.Y() },
      };

      updatedPoints[index] = {
        ...updatedPoints[index],
        curvePoints: updatedCurvePoints,
        objectPosition: calculateMidPoint(updatedCurvePoints),
      };

      if (updatedPoints[index].animationType !== ANIMATION_TYPES.NONE) {
        updatedPoints[index] = {
          ...updatedPoints[index],
          animation: {
            ...updatedPoints[index].animation,
            distance: {
              x:
                updatedPoints[index].animation.motionPoint.x -
                calculateMidPoint(updatedCurvePoints).x,
              y:
                updatedPoints[index].animation.motionPoint.y -
                calculateMidPoint(updatedCurvePoints).y,
            },
          },
        };
      }

      setElement({ ...element, obstacles: updatedPoints });
    });
    // Update first control point x & y
    setDraggedItem(firstControlPoint);
    firstControlPoint.on("up", (e) => {
      if (getDraggedItem() !== firstControlPoint.id) return;

      const updatedPoints = [...element.obstacles];

      const updatedCurvePoints = {
        ...obstacle.curvePoints,
        firstControlPoint: {
          x: firstControlPoint.X(),
          y: firstControlPoint.Y(),
        },
      };

      updatedPoints[index] = {
        ...updatedPoints[index],
        curvePoints: updatedCurvePoints,
        objectPosition: calculateMidPoint(updatedCurvePoints),
      };

      if (updatedPoints[index].animationType !== ANIMATION_TYPES.NONE) {
        updatedPoints[index] = {
          ...updatedPoints[index],
          animation: {
            ...updatedPoints[index].animation,
            distance: {
              x:
                updatedPoints[index].animation.motionPoint.x -
                calculateMidPoint(updatedCurvePoints).x,
              y:
                updatedPoints[index].animation.motionPoint.y -
                calculateMidPoint(updatedCurvePoints).y,
            },
          },
        };
      }

      setElement({ ...element, obstacles: updatedPoints });
    });
    // Update second control point x & y
    setDraggedItem(secondControlPoint);
    secondControlPoint.on("up", (e) => {
      if (getDraggedItem() !== secondControlPoint.id) return;

      const updatedPoints = [...element.obstacles];

      const updatedCurvePoints = {
        ...obstacle.curvePoints,
        secondControlPoint: {
          x: secondControlPoint.X(),
          y: secondControlPoint.Y(),
        },
      };

      updatedPoints[index] = {
        ...updatedPoints[index],
        curvePoints: updatedCurvePoints,
        objectPosition: calculateMidPoint(updatedCurvePoints),
      };

      if (updatedPoints[index].animationType !== ANIMATION_TYPES.NONE) {
        updatedPoints[index] = {
          ...updatedPoints[index],
          animation: {
            ...updatedPoints[index].animation,
            distance: {
              x:
                updatedPoints[index].animation.motionPoint.x -
                calculateMidPoint(updatedCurvePoints).x,
              y:
                updatedPoints[index].animation.motionPoint.y -
                calculateMidPoint(updatedCurvePoints).y,
            },
          },
        };
      }

      setElement({ ...element, obstacles: updatedPoints });
    });
  }
  board.unsuspendUpdate();
  return [
    firstDataPoint,
    firstControlPoint,
    secondControlPoint,
    secondDataPoint,
  ];
};
