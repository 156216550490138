import JXG from "jsxgraph";

// Utilities
import { getDraggedItem, setDraggedItem } from "./draggedItem";
import { pointOptions } from "./pointUtils";

export const drawBezierCurve = (
  board,
  element,
  edit = false,
  setElement = () => {},
) => {
  board.suspendUpdate();
  const points = [];
  // Curve Data Points
  const firstDataPointOptions = {
    name: 1,
    size: 4,
    fixed: true,
    visible: edit,
    ...pointOptions,
  };
  const secondDataPointOptions = {
    name: 4,
    size: 4,
    fixed: !edit,
    visible: edit,
    ...pointOptions,
  };
  const firstDataPoint = board.create(
    "point",
    [element.points.startPoint.x, element.points.startPoint.y],
    firstDataPointOptions,
  );
  const secondDataPoint = board.create(
    "point",
    [element.points.endPoint.x, element.points.endPoint.y],
    secondDataPointOptions,
  );
  // Curve Control Points
  const firstControlPointOptions = {
    name: 2,
    size: 4,
    fillColor: "red",
    strokeColor: "red",
    fixed: !edit,
    visible: edit,
    ...pointOptions,
  };
  const secondControlPointOptions = {
    name: 3,
    size: 4,
    fillColor: "red",
    strokeColor: "red",
    fixed: !edit,
    visible: edit,
    ...pointOptions,
  };
  const firstControlPoint = board.create(
    "point",
    [element.points.firstControlPoint.x, element.points.firstControlPoint.y],
    firstControlPointOptions,
  );
  const secondControlPoint = board.create(
    "point",
    [element.points.secondControlPoint.x, element.points.secondControlPoint.y],
    secondControlPointOptions,
  );

  points.push(
    firstDataPoint,
    firstControlPoint,
    secondControlPoint,
    secondDataPoint,
  );

  // Curve
  const curveOptions = {
    fixed: true,
    strokeColor: element.color || "black",
    strokeWidth: element.lineWidth || 4,
  };
  const curve = board.create(
    "curve",
    JXG.Math.Numerics.bezier(points),
    curveOptions,
  );

  // If element is editable, update element data
  if (edit) {
    // Update second data point x & y
    setDraggedItem(secondDataPoint);
    secondDataPoint.on("up", (e) => {
      if (getDraggedItem() !== secondDataPoint.id) return;
      setElement({
        ...element,
        points: {
          ...element.points,
          endPoint: {
            x: secondDataPoint.X(),
            y: secondDataPoint.Y(),
          },
        },
      });
    });

    // Update first control point x & y
    setDraggedItem(firstControlPoint);
    firstControlPoint.on("up", () => {
      if (getDraggedItem() !== firstControlPoint.id) return;
      setElement({
        ...element,
        points: {
          ...element.points,
          firstControlPoint: {
            x: firstControlPoint.X(),
            y: firstControlPoint.Y(),
          },
        },
      });
    });

    // Update second control point x & y
    setDraggedItem(secondControlPoint);
    secondControlPoint.on("up", () => {
      if (getDraggedItem() !== secondControlPoint.id) return;
      setElement({
        ...element,
        points: {
          ...element.points,
          secondControlPoint: {
            x: secondControlPoint.X(),
            y: secondControlPoint.Y(),
          },
        },
      });
    });
  }
  board.unsuspendUpdate();
};
