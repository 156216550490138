export function createSVGImage(svg, point) {
  const svgString = svg
    .replace("%%FILL_COLOR%%", point?.fill)
    .replace("%%STROKE_COLOR%%", point?.outline)
    .replace(/%%STROKE_WIDTH%%/g, point?.lineWidth)
    .replace("%%WIDTH%%", 50 - point?.lineWidth / 2)
    .replace("%%HEIGHT%%", 50 - point?.lineWidth / 2)
    .replace("%%Y0%%", point?.lineWidth / 0.9)
    .replace("%%Y1%%", 100 - point?.lineWidth / 2)
    .replace("%%Y2%%", 100 - point?.lineWidth / 2)
    .replace("%%X1%%", point?.lineWidth / 1.25)
    .replace("%%X2%%", 100 - point?.lineWidth / 1.25);

  const encodedSVG = encodeURIComponent(svgString);
  return `data:image/svg+xml;utf8,${encodedSVG}`;
}
