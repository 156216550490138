import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useTokenStore = create(
  persist(
    (set) => ({
      token: null,
      setToken: (token) => set(() => ({ token })),
    }),
    {
      name: "accessToken",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const useAdminStore = create((set) => ({
  isAdmin: null,
  setIsAdmin: (isAdmin) => set(() => ({ isAdmin })),
}));

export const useScenesStore = create((set) => ({
  scene: null,
  setScene: (scene) => {
    set(() => ({ scene }));
  },
  addChain: (chain, nextChainIndex) => {
    set((state) => {
      let chainsToUpdate = [...state.scene.chains];
      chainsToUpdate.splice(nextChainIndex, 0, chain);
      return {
        scene: {
          ...state.scene,
          chains: chainsToUpdate,
        },
      };
    });
  },
  deleteChain: (chainIndex) => {
    set((state) => ({
      scene: {
        ...state.scene,
        chains: state.scene.chains.filter(
          (chain, index) => index !== chainIndex,
        ),
      },
    }));
  },
  editChainStartCoords: (chainIndex, startPointCoords) => {
    set((state) => ({
      scene: {
        ...state.scene,
        chains: state.scene.chains.map((chain, index) => {
          if (index === chainIndex)
            return {
              ...state.scene.chains[chainIndex],
              startPoint: startPointCoords,
            };
          return chain;
        }),
      },
    }));
  },
  addElement: (element, chainIndex, elementIndex) => {
    set((state) => {
      let elementsToUpdate = [...state.scene.chains[chainIndex].elements];
      elementsToUpdate.splice(elementIndex, 0, element);
      return {
        scene: {
          ...state.scene,
          chains: state.scene.chains.map((chain, index) => {
            if (index === chainIndex)
              return {
                ...state.scene.chains[chainIndex],
                elements: elementsToUpdate,
              };
            return chain;
          }),
        },
      };
    });
  },
  updateElement: (element, chainIndex, elementIndex) => {
    set((state) => {
      let elementsToUpdate = [...state.scene.chains[chainIndex].elements];
      elementsToUpdate.splice(elementIndex, 1, element);
      return {
        scene: {
          ...state.scene,
          chains: state.scene.chains.map((chain, index) => {
            if (index === chainIndex)
              return {
                ...state.scene.chains[chainIndex],
                elements: elementsToUpdate,
              };
            return chain;
          }),
        },
      };
    });
  },
  deleteElement: (chainIndex, elementIndex) => {
    set((state) => ({
      scene: {
        ...state.scene,
        chains: state.scene.chains.map((chain, index) => {
          if (index === chainIndex)
            return {
              ...state.scene.chains[chainIndex],
              elements: state.scene.chains[chainIndex].elements.filter(
                (element, index) => index !== elementIndex,
              ),
            };
          return chain;
        }),
      },
    }));
  },
}));

export const useObjectStore = create((set, get) => ({
  focusedObject: { index: null, type: "", animate: false },
  setFocusedObject: (focusedObject) => set(() => ({ focusedObject })),
}));

export const useColorPaletteStore = create((set) => ({
  colorPalette: [],
  setColorPalette: (data) => set({ colorPalette: data }),
}));
