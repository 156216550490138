import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

// Store
import { useAdminStore, useTokenStore } from "../store/store";

// Hooks
import { useTokenData } from "../hooks/useTokenData";

// Services
import { getUserDataFromDB } from "../services/user";

export const AdminAuthGuard = () => {
  const { userId } = useTokenData();
  const { token, setToken } = useTokenStore();
  const { isAdmin, setIsAdmin } = useAdminStore();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await getUserDataFromDB(userId);
        setIsAdmin(userData.admin || false);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (token && !isAdmin) {
      fetchData();
    }
  }, [setIsAdmin, token, userId]);

  if (token) {
    if (isAdmin) {
      return <Outlet />;
    } else if (isAdmin === false) {
      return <Navigate to="/login" replace />;
    } else {
      return;
    }
  } else {
    token && setToken(null);
    return <Navigate to="/login" replace />;
  }
};
