import React from "react";

import { OverlayPanel } from "primereact/overlaypanel";

import { useColorPaletteStore } from "../store/store";

const ColorPaletteOverlay = ({ overlayRef, handleColorClick }) => {
  const { colorPalette } = useColorPaletteStore();
  return (
    <OverlayPanel ref={overlayRef}>
      <div className="flex flex-col gap-4">
        <p className="text-sm italic text-gray-400">Color Palette</p>
        <div className="justify col-span-4 flex max-h-52 max-w-52 flex-wrap items-center gap-4 overflow-y-auto">
          {colorPalette && colorPalette.length ? (
            colorPalette.map((item, index) => (
              <span
                key={`item-${index}`}
                style={{ backgroundColor: item.hex }}
                className="h-8 w-8 cursor-pointer rounded-md border border-slate-400"
                onClick={(e) => handleColorClick(e, item)}
              ></span>
            ))
          ) : (
            <p className="text-xs italic text-gray-500">
              Currently, there are no color palettes available. Please configure
              your color palettes in the settings.
            </p>
          )}
        </div>
      </div>
    </OverlayPanel>
  );
};

export default ColorPaletteOverlay;
