// Utilities
import { getDraggedItem, setDraggedItem } from "./draggedItem";
import { pointOptions } from "./pointUtils";

export const drawGap = (
  board,
  element,
  edit = false,
  setElement = () => {},
) => {
  board.suspendUpdate();

  // Gap Data Points
  const firstDataPointOptions = {
    name: 1,
    size: 4,
    fixed: true,
    visible: true,
    ...pointOptions,
  };
  const secondDataPointOptions = {
    name: 2,
    size: 4,
    fixed: !edit,
    visible: true,
    ...pointOptions,
  };

  const firstDataPoint = board.create(
    "point",
    [element.points.startPoint.x, element.points.startPoint.y],
    firstDataPointOptions,
  );
  const secondDataPoint = board.create(
    "point",
    [element.points.endPoint.x, element.points.endPoint.y],
    secondDataPointOptions,
  );

  // If element is editable, update element data
  if (edit) {
    // Update second data point x & y
    setDraggedItem(secondDataPoint);
    secondDataPoint.on("up", (e) => {
      if (getDraggedItem() !== secondDataPoint.id) return;
      setElement({
        ...element,
        points: {
          ...element.points,
          endPoint: { x: secondDataPoint.X(), y: secondDataPoint.Y() },
        },
      });
    });
  }

  board.unsuspendUpdate();
};
