// Constants

export const getInitialDim = (chains) => {
  let initialDim = { x: 60, y: 60 };

  chains?.forEach((chain) => {
    chain.elements.forEach((element) =>
      Object.values(element.points).forEach(
        (point) =>
          (initialDim.y =
            initialDim.y < Math.abs(point.y)
              ? Math.abs(point.y)
              : initialDim.y),
      ),
    );

    initialDim.x =
      initialDim.x <
      chain?.elements[chain?.elements.length - 1]?.points.endPoint.x
        ? chain?.elements[chain?.elements.length - 1]?.points.endPoint.x
        : initialDim.x;
  });
  return initialDim;
};
