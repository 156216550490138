// Constants
import { RIGHT_CLICK_BUTTON_CODE } from "../constants/constants";

export const openObjectSettings = (
  object,
  index,
  type,
  edit,
  animate,
  focusedObject,
  setFocusedObject,
) => {
  object.on("down", (e) => {
    if (e.button === RIGHT_CLICK_BUTTON_CODE && edit) {
      if (animate?.rotate) cancelAnimationFrame(animate.rotate);
      if (animate?.translate) clearTimeout(animate.translate);
      setFocusedObject({ ...focusedObject, animate: false });
      setFocusedObject({ index, type, animate: false });
    }
  });
};
