import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { useMutation } from "@tanstack/react-query";

import GridSettings from "../components/GridSettings";
import { useColorPaletteStore } from "../store/store";
import { pointOptions } from "../utilities/pointUtils";
import ColorSettings from "../components/ColorSettings";
import { updateCustomColorSettings } from "../services/marbleSettings";

const Settings = ({ dismissDialog }) => {
  const [colors, setColors] = useState([]);
  const { colorPalette, setColorPalette } = useColorPaletteStore();
  const [snapToGrid, setSnapToGrid] = useState(pointOptions.snapToGrid);

  useEffect(() => {
    colorPalette && setColors(colorPalette);
  }, []);

  const updateCustomColorsMutation = useMutation({
    mutationFn: updateCustomColorSettings,
    onSuccess: (_, variables) => {
      setColorPalette(variables.colorPalette);
      dismissDialog();
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const handleSaveChanges = () => {
    updateCustomColorsMutation.mutate({ colorPalette: colors });
    pointOptions.snapToGrid = snapToGrid;
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <p className="text-gray-400">Color Palette</p>
        <div className="rounded-lg border border-slate-100 p-5 shadow-sm">
          <ColorSettings colors={colors} setColors={setColors} />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-gray-400">Grid</p>
        <div className="rounded-lg border border-slate-100 p-5 shadow-sm">
          <GridSettings snapToGrid={snapToGrid} setSnapToGrid={setSnapToGrid} />
        </div>
      </div>
      <div className="flex items-center justify-end gap-5">
        <Button label="Cancel" severity="danger" onClick={dismissDialog} />
        <Button
          label="Save Changes"
          severity="success"
          loading={updateCustomColorsMutation.isPending}
          onClick={handleSaveChanges}
        />
      </div>
    </div>
  );
};

export default Settings;
