export const calculateMidPoint = (points) => {
  // const midPoint = Object.values(points).reduce(
  //   (accumulative, point) => {
  //     accumulative.x += point.x;
  //     accumulative.y += point.y;
  //     return accumulative;
  //   },
  //   { x: 0, y: 0 },
  // );
  // for (let point in midPoint) midPoint[point] = midPoint[point] / 4;

  const numPoints = 100; // Adjust as needed
  const step = 1 / numPoints;
  let maxXPoint = { x: -Infinity, y: null };
  let minXPoint = { x: Infinity, y: null };
  let maxYPoint = { x: null, y: -Infinity };
  let minYPoint = { x: null, y: Infinity };

  // Loop through points along the Bézier curve
  for (let t = 0; t <= 1; t += step) {
    const point = bezierPoint(
      points.startPoint,
      points.firstControlPoint,
      points.secondControlPoint,
      points.endPoint,
      t,
    );

    // Update maxXPoint, minXPoint, maxYPoint, and minYPoint if needed
    if (point.x > maxXPoint.x) {
      maxXPoint = point;
    }
    if (point.x < minXPoint.x) {
      minXPoint = point;
    }
    if (point.y > maxYPoint.y) {
      maxYPoint = point;
    }
    if (point.y < minYPoint.y) {
      minYPoint = point;
    }
  }

  function bezierPoint(P0, P1, P2, P3, t) {
    const x =
      Math.pow(1 - t, 3) * P0.x +
      3 * Math.pow(1 - t, 2) * t * P1.x +
      3 * (1 - t) * Math.pow(t, 2) * P2.x +
      Math.pow(t, 3) * P3.x;
    const y =
      Math.pow(1 - t, 3) * P0.y +
      3 * Math.pow(1 - t, 2) * t * P1.y +
      3 * (1 - t) * Math.pow(t, 2) * P2.y +
      Math.pow(t, 3) * P3.y;
    return { x, y };
  }

  // const maxX = Math.max.apply(
  //   Math,
  //   Object.values(points).map((point) => point.x),
  // );
  // const minX = Math.min.apply(
  //   Math,
  //   Object.values(points).map((point) => point.x),
  // );
  // const maxY = Math.max.apply(
  //   Math,
  //   Object.values(points).map((point) => point.y),
  // );
  // const minY = Math.min.apply(
  //   Math,
  //   Object.values(points).map((point) => point.y),
  // );

  const midPoint = {
    x: (maxXPoint.x + minXPoint.x) / 2,
    y: (maxYPoint.y + minYPoint.y) / 2,
  };

  return midPoint;
};
