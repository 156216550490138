// Utilities
import { getDraggedItem, setDraggedItem } from "./draggedItem";
import { pointOptions } from "./pointUtils";

export const drawLine = (
  board,
  element,
  edit = false,
  setElement = () => {},
) => {
  board.suspendUpdate();

  // Line Data Points
  const firstDataPointOptions = {
    name: 1,
    size: 4,
    fixed: true,
    visible: edit,
    ...pointOptions,
  };
  const secondDataPointOptions = {
    name: 2,
    size: 4,
    fixed: !edit,
    visible: edit,
    ...pointOptions,
  };

  const firstDataPoint = board.create(
    "point",
    [element.points.startPoint.x, element.points.startPoint.y],
    firstDataPointOptions,
  );
  const secondDataPoint = board.create(
    "point",
    [element.points.endPoint.x, element.points.endPoint.y],
    secondDataPointOptions,
  );

  // Ripple Control Point
  const controlPointOptions = {
    name: "Ripple Point",
    label: { color: "red" },
    size: 4,
    fillColor: "green",
    strokeColor: "green",
    fixed: !edit,
    visible: edit,
    ...pointOptions,
  };
  const controlPoint = board.create(
    "point",
    [element.rippleDensity, element.rippleHeight],
    controlPointOptions,
  );

  // Line
  const lineOptions = {
    straightFirst: false,
    straightLast: false,
    fixed: true,
    strokeColor: element.color || "black",
    strokeWidth: element.lineWidth || 4,
  };

  let line = board.create(
    "functionGraph",
    [
      function (x) {
        return (
          Math.sin(
            ((x - firstDataPoint.X()) *
              Math.round(controlPoint.X()) *
              Math.PI *
              2) /
              (secondDataPoint.X() - firstDataPoint.X()),
          ) *
            controlPoint.Y() +
          firstDataPoint.Y()
        );
      },
      firstDataPoint.X(),
      secondDataPoint.X(),
    ],
    lineOptions,
  );

  // If element is editable, update element data
  if (edit) {
    setDraggedItem(secondDataPoint);
    secondDataPoint.on("drag", () => {
      // Update second data point position in x position only
      secondDataPoint.moveTo([secondDataPoint.X(), firstDataPoint.Y()]);

      // Delete old line and create a new line
      board.removeObject(line);

      line = board.create(
        "functionGraph",
        [
          function (x) {
            return (
              Math.sin(
                ((x - firstDataPoint.X()) *
                  Math.round(controlPoint.X()) *
                  Math.PI *
                  2) /
                  (secondDataPoint.X() - firstDataPoint.X()),
              ) *
                controlPoint.Y() +
              firstDataPoint.Y()
            );
          },
          firstDataPoint.X(),
          secondDataPoint.X(),
        ],
        lineOptions,
      );
    });

    // Update second data point x & y
    secondDataPoint.on("up", (e) => {
      if (getDraggedItem() !== secondDataPoint.id) return;
      setElement({
        ...element,
        points: {
          ...element.points,
          endPoint: { x: secondDataPoint.X(), y: 0 },
        },
      });
    });

    setDraggedItem(controlPoint);
    controlPoint.on("drag", () => {
      if (getDraggedItem() !== controlPoint.id) return;
      // Update control point position x position not less than 0
      controlPoint.moveTo([
        controlPoint.X() <= 0 ? 0 : controlPoint.X(),
        controlPoint.Y(),
      ]);
    });

    // Update ripple height and density
    controlPoint.on("up", (e) => {
      setElement({
        ...element,
        rippleDensity: controlPoint.X(),
        rippleHeight: controlPoint.Y(),
      });
    });
  }

  board.unsuspendUpdate();
};
