import React from "react";

// Store
import { useScenesStore } from "../store/store";

// Constants
import { TARGET_TYPES } from "../constants/constants";

// Prime react components
import { InputText } from "primereact/inputtext";
import { Slider } from "primereact/slider";
import { Dropdown } from "primereact/dropdown";

export const TargetSettings = ({
  target,
  handleObjectChange,
  scenesOptions,
}) => {
  return (
    <>
      {target?.type === TARGET_TYPES.FLAG.value ? (
        // Target Scene
        <div className="grid grid-cols-12 items-center gap-3">
          <span className="col-span-4">Target</span>
          <Dropdown
            name="nextScene"
            value={target?.nextScene}
            options={scenesOptions}
            optionLabel="title"
            optionValue="id"
            onChange={(e) => {
              handleObjectChange("nextScene", e.value);
            }}
            className="col-span-8"
          />
        </div>
      ) : (
        // Value
        <div className="grid grid-cols-12 items-center gap-3">
          <span className="col-span-4">Point Value</span>
          <InputText
            name="value"
            keyfilter="int"
            value={target?.value}
            onChange={(e) => {
              handleObjectChange("value", +e.target.value);
            }}
            className="col-span-8"
          />
        </div>
      )}
      {/* Size */}
      <div className="grid grid-cols-12 items-center gap-3">
        <span className="col-span-4">Size: {target?.size}</span>
        <Slider
          max={40}
          min={1}
          value={target?.size}
          onChange={(e) => handleObjectChange("size", e.value)}
          className="col-span-8"
        />
      </div>
    </>
  );
};
