import React, { memo, useState } from "react";
import { useForm } from "react-hook-form";

// Store
import { useScenesStore } from "../store/store";

// Constants
import { NEW_CHAIN } from "../constants/constants";

// Components
import { PreviewElement } from "./PreviewElement";

// Prime React Components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

export const Chain = memo(function Chain({ chainIndex, setModal }) {
  const [editStartCoords, setEditStartCoords] = useState(false);
  const scene = useScenesStore((state) => state.scene);

  const addChain = useScenesStore((state) => state.addChain);
  const deleteChain = useScenesStore((state) => state.deleteChain);
  const deleteElement = useScenesStore((state) => state.deleteElement);

  const moveChain = (direction, index) => {
    const newIndex = index + direction;
    const chain = scene.chains[index];
    deleteChain(index);
    addChain(chain, newIndex);
  };

  return (
    <>
      {/* Chain Header */}
      <div className="flex justify-between">
        {/* Chain Starting Position Components */}
        {editStartCoords ? (
          <EditCoordsTemplate
            chainIndex={chainIndex}
            setEditStartCoords={setEditStartCoords}
          />
        ) : (
          <CoordsTemplate
            chainIndex={chainIndex}
            setEditStartCoords={setEditStartCoords}
          />
        )}
        {/* Chain Number of Elements */}
        <div className="flex gap-3">
          <p>
            Number of Segments:&nbsp;
            {scene.chains[chainIndex].elements.length}
          </p>
        </div>
        {/* Chain Sort Buttons */}
        <div className="flex items-start justify-start gap-3">
          {/* Move Down Button */}
          {chainIndex !== scene.chains.length - 1 && (
            <i
              data-pr-tooltip="Move down"
              className="custom-tooltip pi pi-chevron-down cursor-pointer text-lg font-bold text-blue-500"
              onClick={() => {
                moveChain(1, chainIndex);
              }}
            />
          )}
          {/* Move Up Button */}
          {chainIndex !== 0 && (
            <i
              data-pr-tooltip="Move up"
              className="custom-tooltip pi pi-chevron-up cursor-pointer text-lg font-bold text-blue-500"
              onClick={() => {
                moveChain(-1, chainIndex);
              }}
            />
          )}
        </div>
      </div>
      {/* Chain Main */}
      <div className="flex min-h-72 items-center gap-3 overflow-x-auto rounded-lg border border-slate-900 px-4 py-6">
        {/* Element Map */}
        {scene.chains[chainIndex].elements?.map((element, elementIndex) => (
          <div key={elementIndex} className="relative flex items-center gap-3 ">
            {/* Create New Prior Element Action Button */}
            <div className="flex flex-col gap-3">
              <Button
                onClick={() =>
                  setModal({
                    show: true,
                    chainIndex: chainIndex,
                    elementIndex: elementIndex,
                    element: null,
                  })
                }
                className="pi pi-plus flex-shrink-0 p-2"
                pt={{
                  label: { className: "hidden" },
                }}
              />
            </div>
            {/* Graph */}
            <div className="h-64 w-64">
              <PreviewElement
                element={element}
                elementIndex={elementIndex}
                chainIndex={chainIndex}
                navigate={false}
                edit={false}
                setElement={() => {}}
              />
            </div>
            {/* Element Action Buttons */}
            <Button
              onClick={() => deleteElement(chainIndex, elementIndex)}
              rounded
              severity="danger"
              className="pi pi-times absolute -right-4 -top-4 p-2 "
              pt={{
                label: { className: "hidden" },
              }}
            />
            <Button
              onClick={() =>
                setModal({
                  show: true,
                  chainIndex: chainIndex,
                  elementIndex: elementIndex,
                  element: scene.chains[chainIndex].elements[elementIndex],
                })
              }
              rounded
              severity="info"
              className="pi pi-pencil absolute -top-4 right-6 p-2 "
              pt={{
                label: { className: "hidden" },
              }}
            />
          </div>
        ))}
        {/* Create New Element Action Button */}
        <Button
          onClick={() =>
            setModal({
              show: true,
              chainIndex: chainIndex,
              elementIndex: scene.chains[chainIndex].elements.length,
              element: null,
            })
          }
          className="pi pi-plus flex-shrink-0 p-2"
          pt={{
            label: { className: "hidden" },
          }}
        />
      </div>
      {/* Chain Action Buttons */}
      <div className="flex justify-end gap-3">
        <Button
          label="Delete Chain"
          severity="danger"
          disabled={scene.chains.length === 1}
          onClick={() => deleteChain(chainIndex)}
        />
        <Button
          label="Add Chain"
          onClick={() => addChain(NEW_CHAIN, chainIndex + 1)}
        />
      </div>
    </>
  );
});

const EditCoordsTemplate = ({ chainIndex, setEditStartCoords }) => {
  const scene = useScenesStore((state) => state.scene);
  const editChainStartCoords = useScenesStore(
    (state) => state.editChainStartCoords,
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      x: scene?.chains[chainIndex]?.startPoint?.x,
      y: scene?.chains[chainIndex]?.startPoint?.y,
    },
  });

  const handleEditStartCoords = (data) => {
    const startPoint = { x: +data.x, y: +data.y };
    editChainStartCoords(chainIndex, startPoint);
    setEditStartCoords(false);
  };
  return (
    <form
      onSubmit={handleSubmit(handleEditStartCoords)}
      className="flex flex-col gap-3"
    >
      <div className="flex items-center gap-3">
        <p>Starting Position (X): </p>
        <InputText
          {...register("x", { required: "Start Position X is required" })}
          className="rounded-lg border border-slate-900 p-2"
        />
        {errors.x && <p className="text-red-500">{errors.x.message}</p>}
      </div>
      <div className="flex items-center gap-3">
        <p>Starting Position (Y): </p>
        <InputText
          {...register("y", { required: "Start Position Y is required" })}
          className="rounded-lg border border-slate-900 p-2"
        />
        {errors.y && <p className="text-red-500">{errors.y.message}</p>}
      </div>

      <div className="align-center flex gap-3 ">
        <Button
          label="Cancel"
          severity="danger"
          type="button"
          onClick={() => setEditStartCoords(false)}
        />
        <Button severity="success" label="Confirm" />
      </div>
    </form>
  );
};

const CoordsTemplate = ({ chainIndex, setEditStartCoords }) => {
  const scene = useScenesStore((state) => state.scene);
  return (
    <div className="flex flex-col gap-3">
      <p>Starting Position (X): {scene.chains[chainIndex].startPoint.x}</p>
      <p>Starting Position (Y): {scene.chains[chainIndex].startPoint.y}</p>
      <Button
        onClick={() => setEditStartCoords(true)}
        severity="info"
        label="Edit Starting Position"
      />
    </div>
  );
};
