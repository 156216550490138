import React, { useState } from "react";

import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";

const ColorSettings = ({ colors, setColors }) => {
  const [selectedColor, setSelectedColor] = useState({ hex: "#000000" });

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 flex items-center justify-center gap-4 rounded-md border border-slate-100 p-6 py-6 lg:col-span-5">
          <div className="flex items-center justify-around gap-4">
            <ColorPicker
              value={selectedColor.hex}
              onChange={(e) => setSelectedColor({ hex: `#${e.value}` })}
              inline
              pt={{
                input: { className: "w-20 h-20" },
              }}
            />
            <div className="flex flex-col items-center gap-1">
              <div
                style={{ backgroundColor: selectedColor.hex }}
                className={`h-16 w-16 rounded-md border border-slate-400`}
              ></div>
              <p className="text-sm font-semibold text-gray-500">
                {selectedColor.hex}
              </p>
            </div>
          </div>
        </div>
        <div className="col-span-12 flex items-center justify-center lg:col-span-1 lg:hidden">
          <Button
            icon="pi pi-angle-double-down"
            onClick={() => {
              setColors([...colors, selectedColor]);
            }}
          />
        </div>
        <div className="col-span-12 hidden items-center justify-center lg:col-span-1 lg:flex">
          <Button
            icon="pi pi-angle-double-right"
            onClick={() => {
              setColors([...colors, selectedColor]);
            }}
          />
        </div>
        <div className="col-span-12 flex max-h-56 flex-wrap items-start gap-3 overflow-y-auto rounded-md border border-slate-100 bg-slate-50 p-6 lg:col-span-6">
          {colors.length ? (
            colors.map((color, index) => {
              return (
                <span
                  key={`item-${index}`}
                  style={{ backgroundColor: color.hex }}
                  className="relative h-12 w-12 rounded-md border border-slate-400"
                >
                  <span
                    onClick={() => {
                      const newColors = [...colors];
                      newColors.splice(index, 1);
                      setColors(newColors);
                    }}
                    className="absolute right-0 top-0 flex h-4 w-4 -translate-y-1/2 translate-x-1/2 cursor-pointer items-center justify-center rounded-full   text-red-600"
                  >
                    <i className="pi pi-times-circle h-4 w-4 rounded-full bg-slate-50 "></i>
                  </span>
                </span>
              );
            })
          ) : (
            <p className="flex h-full items-center justify-center text-sm italic text-gray-400">
              No color palettes are available. Please select a color and click
              the button to add a new palette.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ColorSettings;
