export const setBoardDimensions = (initialDim = { x: 60, y: 60 }) => {
  const dim = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0,
  };

  dim.minX = -Math.ceil((initialDim.x * 0.25) / 100) * 100;
  dim.maxX = Math.ceil((initialDim.x * 1.25) / 100) * 100;
  dim.minY = -Math.ceil((initialDim.y * 1.25) / 100) * 100;
  dim.maxY = Math.ceil((initialDim.y * 1.25) / 100) * 100;
  return dim;
};
