// Utilities
import { getElementAngle } from "./getElementAngle";

export const drawTransitionWithTangents = (
  prevElement,
  element,
  transitionElementRadius,
) => {
  const firstElementAngle = getElementAngle(
    prevElement,
    prevElement.points.secondControlPoint,
    prevElement.points.endPoint,
  );
  const secondElementAngle = getElementAngle(
    element,
    element.points.startPoint,
    element.points.firstControlPoint,
  );

  let medianAngle = (firstElementAngle + secondElementAngle) / 2;

  if (medianAngle > 0.5 * Math.PI && medianAngle < 1.5 * Math.PI)
    medianAngle += Math.PI;
  const transitionHorizontalLength =
    transitionElementRadius * Math.cos(medianAngle);
  const transitionVerticalLength =
    transitionElementRadius * Math.sin(medianAngle);

  const transitionElementFirstControlPointX =
    0.4 * transitionElementRadius * Math.cos(firstElementAngle);
  const transitionElementFirstControlPointY =
    0.4 * transitionElementRadius * Math.sin(firstElementAngle);

  const transitionElementSecondControlPointX =
    transitionHorizontalLength -
    0.4 * transitionElementRadius * Math.cos(secondElementAngle);
  const transitionElementSecondControlPointY =
    transitionVerticalLength -
    0.4 * transitionElementRadius * Math.sin(secondElementAngle);

  const { points, rippleHeight, rippleDensity, targets, obstacles, ...rest } =
    prevElement;

  const transitionElement = {
    ...rest,
    type: 6,
    points: {
      startPoint: { x: 0, y: 0 },
      firstControlPoint: {
        x: transitionElementFirstControlPointX,
        y: transitionElementFirstControlPointY,
      },
      secondControlPoint: {
        x: transitionElementSecondControlPointX,
        y: transitionElementSecondControlPointY,
      },
      endPoint: {
        x: transitionHorizontalLength,
        y: transitionVerticalLength,
      },
    },
  };

  return transitionElement;
};
